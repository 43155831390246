import React from 'react';
import '../styles/settlement-router-styles.scss';
// [assets] photo in boxes
import owkPhoto from '../assets/images/owk-photo.png';
// [assets] symbol
import { ReactComponent as IcSeaside } from '../assets/images/symbol/ic-seaside.svg';
// [assets] shapes
import arrowRightBlack from '../assets/images/shapes/arrow-right-black.png';
import arrowLeftBlack from '../assets/images/shapes/arrow-left-black.png';
// [assets] pictures
import owkImg from '../assets/images/pictures/owk.png';
// [assets]
import quote from '../assets/images/quote.png'
import lineWhite from '../assets/images/line-white.png';

// ----------------------------------------------------------------------

function SettlementRouter() {
    return (
        <div className='settlementrouter-container'>
            <div className='about-section-container'>

                <div className='sr-col'>
                    <p className='title-section'>w sprzedazy _</p>

                    <div className='tooltip-container'>
                        <div className='tooltip-text'>
                            <img src={owkPhoto} alt='Osada Wzgórza Kaszubskie' className='miniatures-settlement' />
                            <div>
                                <p className='title-settlement'>Osada Wzgórza Kaszubskie</p>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    Pomorze
                                </span>
                            </div>
                            <img src={arrowRightBlack} alt='Strzałka' className='arrow-right' />
                        </div>
                        <div className='tooltip-text'>
                            <img src={owkPhoto} alt='Osada Wzgórza Kaszubskie' className='miniatures-settlement' />
                            <div>
                                <p className='title-settlement'>Osada Wzgórza Kaszubskie</p>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    Pomorze
                                </span>
                            </div>
                            <img src={arrowRightBlack} alt='Strzałka' className='arrow-right' />
                        </div>
                        <div className='tooltip-text'>
                            <img src={owkPhoto} alt='Osada Wzgórza Kaszubskie' className='miniatures-settlement' />
                            <div>
                                <p className='title-settlement'>Osada Wzgórza Kaszubskie</p>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    Pomorze
                                </span>
                            </div>
                            <img src={arrowRightBlack} alt='Strzałka' className='arrow-right' />
                        </div>

                        <div className='pagination'>
                            <div className='circle'>
                                <img src={arrowLeftBlack} alt='Strzałka' className='arrow-disabled' />
                            </div>
                            <div className='pagin-number'>1 / 2</div>
                            <div className='circle'>
                                <img src={arrowRightBlack} alt='Strzałka' />
                            </div>
                        </div>

                    </div>

                    <div className='settlement-cont'>
                        <img src={owkImg} alt='osada' />
                        <div className='settlement-mask'>
                            <div className='settlement-name'>Osada Wzgórza Kaszubskie</div>
                            <img src={lineWhite} alt='line' width='140px' />
                            <div>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    <div>Pomorze</div>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>



                <div className='sr-col'>
                    <div className='settlement-cont'>
                        <img src={owkImg} alt='osada' />
                        <div className='settlement-mask'>
                            <div className='settlement-name'>Osada Wzgórza Kaszubskie</div>
                            <img src={lineWhite} alt='line' width='140px' />
                            <div>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    <div>Pomorze</div>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='quote-container'>
                        <img src={quote} alt='cytat' className='quote-img' />

                        <div className='quote-content'>Usługa na bardzo wysokim poziomie. Rzetelni doświadczeni fachowcy, którzy świetnie potrafią się wsłuchać w potrzeby Klienta  Znają się na swoim fachu i robią kawał dobrej roboty.</div>

                        <div className='pagination'>
                            <div className='circle'>
                                <img src={arrowLeftBlack} alt='Strzałka' className='arrow-disabled' />
                            </div>
                            <div className='pagin-number'>1 / 2</div>
                            <div className='circle'>
                                <img src={arrowRightBlack} alt='Strzałka' />
                            </div>
                        </div>
                    </div>
                </div>




                <div className='sr-col'>
                    <p className='title-section title-sold'>sprzedane _</p>

                    <div className='tooltip-container'>
                        <div className='tooltip-text tooltip-inactive'>
                            <img src={owkPhoto} alt='Osada Wzgórza Kaszubskie' className='miniatures-settlement' />
                            <div>
                                <p className='title-settlement'>Osada Wzgórza Kaszubskie</p>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    Pomorze
                                </span>
                            </div>
                            <img src={arrowRightBlack} alt='Strzałka' className='arrow-right' />
                        </div>
                        <div className='tooltip-text tooltip-inactive'>
                            <img src={owkPhoto} alt='Osada Wzgórza Kaszubskie' className='miniatures-settlement' />
                            <div>
                                <p className='title-settlement'>Osada Wzgórza Kaszubskie</p>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    Pomorze
                                </span>
                            </div>
                            <img src={arrowRightBlack} alt='Strzałka' className='arrow-right' />
                        </div>
                        <div className='tooltip-text tooltip-inactive'>
                            <img src={owkPhoto} alt='Osada Wzgórza Kaszubskie' className='miniatures-settlement' />
                            <div>
                                <p className='title-settlement'>Osada Wzgórza Kaszubskie</p>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    Pomorze
                                </span>
                            </div>
                            <img src={arrowRightBlack} alt='Strzałka' className='arrow-right' />
                        </div>

                        <div className='pagination'>
                            <div className='circle'>
                                <img src={arrowLeftBlack} alt='Strzałka' className='arrow-disabled' />
                            </div>
                            <div className='pagin-number'>1 / 2</div>
                            <div className='circle'>
                                <img src={arrowRightBlack} alt='Strzałka' />
                            </div>
                        </div>
                    </div>

                    <div className='settlement-cont'>
                        <img src={owkImg} alt='osada' />
                        <div className='settlement-mask'>
                            <div className='settlement-name'>Osada Wzgórza Kaszubskie</div>
                            <img src={lineWhite} alt='line' width='140px' />
                            <div>
                                <span className='region-name'>
                                    <IcSeaside className='region-icon' />
                                    <div>Pomorze</div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default SettlementRouter;