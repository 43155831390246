import React, { useEffect } from 'react';
import '../styles/home-styles.scss';
import HeroHome from '../components/HeroHome';

// ----------------------------------------------------------------------

function Home() {

    const bgcFunc = () => {
        const footerBg = document.getElementsByClassName('footer-container')[0];
        footerBg.style.background = 'var(--c-custom-10)';
        // console.info(`kolor footera zmieniony. element: ${footerBg}}`);
    }

    useEffect(() => {
        bgcFunc();
    });

    return (
        <>
            <HeroHome />
        </>
    )
}

export default Home;