import React from 'react';
import '../styles/footer-styles.scss';
// [assets]
import logo from '../assets/logo-site.png';
import iconArrow from '../assets/images/iconArrow.png';
import { ReactComponent as IcFacebook } from '../assets/icons/ic-facebook.svg';
import { ReactComponent as IcLinkedIn } from '../assets/icons/ic-linkedin.svg';
import { ReactComponent as IcInstagram } from '../assets/icons/ic-instagram.svg';
import { ReactComponent as IcYoutube } from '../assets/icons/ic-youtube.svg';

// ----------------------------------------------------------------------

const current = new Date();
const copyrightDate = `${current.getFullYear()}`;

function Footer() {
    return (
        <footer className='footer-class'>
            <div className='footer-container'>
                <div className='first-menu'>
                    <img src={logo} alt='Ziemia Inwestycje' width='142' height='42' className='logo' />
                    <ul className='biuro-menu'>
                        <li className='title'>BIURO</li>
                        <li>Ziemia Inwestycje Sp. z o.o. Sp. k.</li>
                        <li>ul. Prosta 32</li>
                        <li>00-838 Warszawa</li>
                    </ul>
                    <ul className='biuro-menu'>
                        <li className='title'>KONTAKT</li>
                        <li><a href="{NULL}" className='under-link'>+48 573 258 652</a></li>
                        <li><a href="{NULL}" className='under-link'>kontakt@ziemiainwestycje.pl</a></li>
                    </ul>
                    <li className="buy-ground">
                        <a href="{NULL}"><span>ZNAJDŹ DZIAŁKĘ</span></a>
                        <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                    </li>
                </div>
                <div className='second-menu'>
                    <ul className='links-menu'>
                        <li className='link-element'><a href='slownik' alt='słownik' className='under-link'>SŁOWNIK</a></li>
                        <li className='link-element'><a href="sprzedaj-grunt" alt='wyceń nieruchomość' className='under-link'>WYCEŃ NIERUCHOMOŚĆ</a></li>
                        <li className='link-element'><a href="do-pobrania" alt='do pobrania' className='under-link'>DO POBRANIA</a></li>
                        <li className='link-element'><a href="mapa-strony" alt='mapa strony' className='under-link'>MAPA STRONY</a></li>
                        <li className='link-element'><a href="kariera" alt='kariera' className='under-link'>KARIERA</a></li>
                        <li className='link-element'><a href='kontakt' alt='kontakt' className='under-link'>KONTAKT</a></li>
                    </ul>
                    <ul className='social-menu'>
                        <li className='social-element'><IcFacebook className='social-element-fill' /></li>
                        <li className='social-element'><IcLinkedIn className='social-element-fill' /></li>
                        <li className='social-element'><IcInstagram className='social-element-fill' /></li>
                        <li className='social-element'><IcYoutube className='social-element-fill' /></li>
                    </ul>
                </div>
                <div className='third-menu'>
                    <ul className='links-menu'>
                        <li className='copyright'>© {copyrightDate} Copyright Ziemia Inwestycje.</li>
                        <li className='policy'>
                            <a className='policy-link' href='polityka-prywatnosci'>Polityka prywatności</a>
                        </li>
                    </ul>
                    <ul className='social-menu'>
                        <li className='design'>Design: <a href='{NULL}' className='design-owner'>Ficturo</a></li>
                    </ul>
                </div>
            </div>
        </footer >
    )
}

export default Footer;