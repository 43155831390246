import React, { useState } from 'react';
import '../styles/purchasing-process-styles.scss';
// import '../styles/libs/bootstrap.min.scss';
import TitleDir from '../components/TitleDir';
import SettlementRouter from '../components/SettlementRouter';
// [assets] img
import iconStep1 from '../assets/images/purchasing-process/step1.png';
import iconStep2 from '../assets/images/purchasing-process/step2.png';
import iconStep3 from '../assets/images/purchasing-process/step3.png';
import iconStep4x7 from '../assets/images/purchasing-process/step4x7.png';
import iconStep5 from '../assets/images/purchasing-process/step5.png';
import iconStep5credit from '../assets/images/purchasing-process/step5_credit.png';
import iconStep6 from '../assets/images/purchasing-process/step6.png';
import iconArrow from '../assets/images/iconArrow.png';

// ----------------------------------------------------------------------

function PurchasingProcess() {

    const [cash, setCash] = useState(true);

    return (
        <>
            <div className='purchasing-container bootstrap-cont'>
                <div className='purchasing-section-container'>

                    <div className='purchasing-left'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 purchasing-about">
                                    <div className="tf-title mb-30" data-aos="fade-up" data-aos-duration="800">
                                        <TitleDir>Proces zakupu _</TitleDir>
                                        <br />
                                        <h4 className="main-title">Formalności bierzemy na siebie</h4>
                                        <p className='title-hero-secondary'>Nasz prosty, przejrzysty i przede wszystkim sprawdzony przez setki klientów proces sprzedaży działek sprawi, że Twoje rozterki i wahania znikną.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='purchasing-right'>

                        <div className='purchase-type'>
                            <div className={`point ${cash ? 'active' : ''}`} onClick={() => setCash(true)}>
                                <div>Zakup gotówkowy</div>
                            </div>
                            <div className={`point ${!cash ? 'active' : ''}`} onClick={() => setCash(false)}>
                                <div>Zakupy na kredyt</div>
                            </div>
                        </div>

                        {/* STEPS CONTAINER */}
                        <div className='purchase-list'>

                            <div className='lineY' />

                            <div className='dot'>
                                <img src={iconStep1} alt='Krok 1' />
                            </div>
                            <TitleDir>Krok 1 _</TitleDir>
                            <p className='purchase-name'>Wybór działki i jej wstępna rezerwacja</p>
                            <p className='title-hero-secondary'>Wstępna rezerwacja działki u sprzedawcy (zdalnego lub terenowego) na 3-4 dni po zapoznaniu się z ofertą. Spacer online, zdjęcia, zobaczenie działki na żywo. Udostępnienie klientowi dokumentów dot osady (mapy, wypis i wyrys, badania)</p>

                            <div className='dot'>
                                <img src={iconStep2} alt='Krok 2' />
                            </div>
                            <TitleDir>Krok 2 _</TitleDir>
                            <p className='purchase-name'>Decyzja o dokonaniu zakupu</p>
                            <p className='title-hero-secondary'>Klient przesyła swoje dane potrzebne do umowy. </p>

                            <div className='dot'>
                                <img src={iconStep3} alt='Krok 3' />
                            </div>
                            <TitleDir>Krok 3 _</TitleDir>
                            <p className='purchase-name'>Podpisanie umowy</p>
                            <p className='title-hero-secondary'>Po otrzymaniu kompletu danych podpisujemy umowę. Można to zrobić na trzy sposoby: listownie, zdalnie lub osobiście bezpośrednio na działkach albo w innym dogodnym dla ciebie miejscu.</p>

                            <div className='dot'>
                                <img src={iconStep4x7} alt='Krok 4' />
                            </div>
                            <TitleDir>Krok 4 _</TitleDir>
                            <p className='purchase-name'>Wpłata środków na poczet rezerwacji</p>
                            <p className='title-hero-secondary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas amet dis habitasse donec tellus purus velit nunc pharetra. Quis felis nunc faucibus curabitur ultrices ut. Nisl lectus risus, ornare tortor. Eget cras maecenas consectetur dis felis elit auctor consequat egestas.</p>

                            <div className='dot'>
                                <img src={cash ? iconStep5 : iconStep5credit} alt='Krok 5' />
                            </div>
                            <TitleDir>Krok 5 _</TitleDir>
                            <p className='purchase-name'>{`${cash ? 'Wpłata reszty kwoty przed aktem notarialnym' : 'Procesowanie kredytu'}`}</p>
                            <p className='title-hero-secondary'>{cash ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas amet dis habitasse donec tellus purus velit nunc pharetra. Quis felis nunc faucibus curabitur ultrices ut. Nisl lectus risus, ornare tortor. Eget cras maecenas consectetur dis felis elit auctor consequat egestas.' : 'Czekamy na przyznanie klientowi przez bank kredytu.'}</p>

                            <div className='dot'>
                                <img src={iconStep6} alt='Krok 6' />
                            </div>
                            <TitleDir>Krok 6 _</TitleDir>
                            <p className='purchase-name'>Podpisanie aktu natarialnego</p>
                            <p className='title-hero-secondary'>Stajesz się właścicielem działki! Poleć nas innym. </p>

                            {!cash ? <>
                                <div className='dot'>
                                    <img src={iconStep4x7} alt='Krok 7' />
                                </div>
                                <TitleDir>Krok 7 _</TitleDir>
                                <p className='purchase-name'>Wypłata środków przez bank na rachunek ZI</p>
                                <p className='title-hero-secondary'>Lorem ipsum dolor sit amet </p>
                            </> : undefined}
                        </div>

                        <div className="buy-ground">
                            <a href="{NULL}"><span>ZNAJDŹ DZIAŁKĘ DLA SIEBIE</span></a>
                            <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                        </div>

                    </div>
                </div>
            </div>

            {/*  */}
            <SettlementRouter />
        </>
    )
}

export default PurchasingProcess;