import React from 'react';
import HeroBlank from '../components/HeroBlank';
import '../styles/policy-styles.scss';

// ----------------------------------------------------------------------

function Policy() {

    const title = 'Polityka prywatności';
    const description = `Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw. cookies. `;

    return (
        <>
            <HeroBlank title={title} description={description} />
            <div className='policy-container bootstrap-cont'>
                <div className='content-container'>


                </div>
            </div>
        </>
    )
}

export default Policy;