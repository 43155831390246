import React from 'react';
import '../styles/contact-styles.scss';
import AccordionFaq from '../components/AccordionFaq';
// import "bootstrap/dist/css/bootstrap.min.css";
import '../styles/libs/bootstrap.min.scss';
import TitleDir from '../components/TitleDir';
import icFrame from '../assets/images/ic-frame.png';
import contactMap from '../assets/images/contact-map.png';
import { ReactComponent as IcFacebook } from '../assets/icons/ic-facebook.svg';
import { ReactComponent as IcLinkedIn } from '../assets/icons/ic-linkedin.svg';
import { ReactComponent as IcInstagram } from '../assets/icons/ic-instagram.svg';
import { ReactComponent as IcYoutube } from '../assets/icons/ic-youtube.svg';
import ContactForm from '../components/ContactForm';
// [assets]
import odwLogo from '../assets/images/contact/odw-logo.png';
import ojdLogo from '../assets/images/contact/ojd-logo.png';
import okzLogo from '../assets/images/contact/okz-logo.png';
import olpLogo from '../assets/images/contact/olp-logo.png';
import osdLogo from '../assets/images/contact/osd-logo.png';
import osgLogo from '../assets/images/contact/osg-logo.png';
import owkLogo from '../assets/images/contact/owk-logo.png';
import ogbLogo from '../assets/images/contact/ogb-logo.png';

// ----------------------------------------------------------------------

const accordionData = [{
    title: 'Gdzie położone są Osady?',
    text: 'Nasze osady zlokalizowane są w rejonach atrakcyjnych turystycznie i wypoczynkowo. Jednak ich zaciszne położenie, często w pobliżu lasów, pozwala zachować kameralnych charakter i cieszyć się spokojem. Osady są idealnym miejscem na drugi dom!'
},
{
    title: 'Jaki rodzaj działek sprzedajemy?',
    text: 'Nasze Osady nadają się pod zabudowę: mieszkaniową jednorodzinną lub rekreacyjną, a czasem istnieje także możliwość prowadzenia działalności turystycznej.'
}, {
    title: 'Jakie media będę mieć na działce?',
    text: 'Gwarantujemy dostęp do sieci energetycznej oraz sieci wodociągowej, a także budowę utwardzonych dróg wewnętrznych, które zapewnią komfortowy dojazd do Twojej działki.'
},
{
    title: 'Jaką mam pewność, że media będą w terminie w jakim Państwo się deklarują?',
    text: 'Terminy mediów są zagwarantowane odpowiednim zapisem w akcie notarialnym.'
},
{
    title: 'Czy Państwo są pośrednikami?',
    text: 'Nie. Jesteśmy właścicielami działek, a dzięki temu, że nabywają Państwo działkę od firmy, nie płacą Państwo standardowego podatku od czynności cywilnoprawnych w wysokości 2% wartości nieruchomości.'
}]

function Contact() {
    return (
        <>
            <div className='contact-container bootstrap-cont'>
                <div className='contact-section-container'>
                    <div className='contact-left'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 purchasing-contact">
                                    <div className="tf-title mb-30" data-aos="fade-up" data-aos-duration="800">
                                        <p className="contact-title">Skontaktuj się z nami</p>

                                        <div className='contact-data'>
                                            <TitleDir>Kontakt _</TitleDir>
                                            <ul className='list-contact'>
                                                <li>+48 573 258 652</li>
                                                <li>kontakt@ziemiainwestycje.pl</li>
                                            </ul>
                                            <p>Chcesz złozyć reklamację? <a href={'#'}>Przejdź do formularza</a></p>
                                        </div>

                                        <div className='contact-data'>
                                            <TitleDir>Dane firmy _</TitleDir>
                                            <ul className='list-contact'>
                                                <li>Ziemia Inwestycje Sp. z o.o. Sp. k.</li>
                                                <li>ul. Prosta 32</li>
                                                <li>00-838 Warszawa</li>
                                                <li>NIP: 522-304-58-93</li>
                                            </ul>
                                        </div>

                                        <div className='contact-data'>
                                            <TitleDir>Numer konta bankowego _</TitleDir>
                                            <ul className='list-contact'>
                                                <li>mBank SA</li>
                                                <li>79 1140 1010 0000 3904 1500 1005 <img src={icFrame} alt='kopiuj' /></li>
                                            </ul>
                                        </div>

                                        <a href="https://www.google.com/maps/place/Ziemia+Inwestycje/@52.2314529,20.9857343,17z/data=!3m1!4b1!4m5!3m4!1s0x471ecd51adbdfea9:0xb4d167bf0e2176a6!8m2!3d52.2314496!4d20.987923" target="_blank" rel="noopener noreferrer">
                                            <img src={contactMap} alt='mapa' className="map-contact" />
                                        </a>

                                        <div className='social-icons-contact'>
                                            <p className='social-menu-title'>Social Media:</p>
                                            <ul className='social-menu'>
                                                <li className='social-element'><IcFacebook className='social-element-fill' /></li>
                                                <li className='social-element'><IcLinkedIn className='social-element-fill' /></li>
                                                <li className='social-element'><IcInstagram className='social-element-fill' /></li>
                                                <li className='social-element'><IcYoutube className='social-element-fill' /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <ContactForm />

                                <div className="guardians">
                                    <div className="contact-title">Opiekunowie Twoich osad</div>
                                </div>

                                <div className="container guardians-list">
                                    <div className="row">
                                        <div className="col-3 titleBorder">
                                            <p>Krystian Pankiewicz</p>
                                            <p className="title">Opiekun klienta</p>
                                        </div>

                                        <div className="custom-1" />

                                        <div className="col-3 titleBorder">
                                            <p>Rafał Skwarzyński</p>
                                            <p className="title">Opiekun klienta</p>
                                        </div>


                                        <div className="custom-1" />

                                        <div className="col-3 titleBorder">
                                            <p>Marcin Kaczorek</p>
                                            <p className="title">Opiekun klienta</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="container guardians-avatar">
                                    <div className="row" >
                                        <div className="col-3 titleBorder">
                                            <img src={owkLogo} alt="Osada Wzgórza Kaszubskie" />
                                            <img src={olpLogo} alt="Osada Lubuska Przystań" />
                                            <img src={okzLogo} alt="Osada Kujawska Zatoka" />
                                        </div>

                                        <div className="custom-1" />

                                        <div className="col-3 titleBorder">
                                            <img src={osgLogo} alt="Osada Srebrna Góra" />
                                            <img src={odwLogo} alt="Osada Dolina Warty" />
                                            <img src={osdLogo} alt="Osada Słowińskie Dęby" />
                                        </div>


                                        <div className="custom-1" />

                                        <div className="col-3 titleBorder">
                                            <img src={ojdLogo} alt="Osada Jezioro Dołgie" />
                                            <img src={owkLogo} alt="Osada Góry Bardzkie" />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="container guardians-avatar">
                                    <div className="row" >
                                        <div className="col-3 titleBorder">
                                            <img src={owkLogo} alt="Osada Wzgórza Kaszubskie" />
                                        </div>
                                        <div className="custom-1" />
                                        <div className="col-3"></div>
                                        <div className="custom-1" />
                                        <div className="col-3"></div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>





    )
}

export default Contact;



// <Field
//   className="main"
//   type="number"
//   name="numberInput"
//   ... other props here ...
// />