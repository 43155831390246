import React from 'react';
import HeroBlank from '../components/HeroBlank';
import SettlementRouter from '../components/SettlementRouter';
import '../styles/sitemap-styles.scss';

// ----------------------------------------------------------------------

function Sitemap() {

    const title = 'Mapa strony';
    const description = `Lista wszystkich linków w obrębie naszej witryny. `;

    return (
        <>
            <HeroBlank title={title} description={description} />
            <div className='sitemap-container'>
                <div className='content-container'>

                    <div className='url-lists'>
                        <div className='col-list'>
                            <div className='url-title'>Ziemia Inwestycje</div>
                            <ul className='url-el'>
                                <li>Strona główna</li>
                                <li>Nasze osady</li>
                                <li>O nas</li>
                                <li>Czytelnia</li>
                                <li>Kariera</li>
                                <li>Kontakt</li>
                                <li>Znajdź działkę</li>
                                <li>Sprzedaj grunt</li>
                            </ul>

                            <div className='url-title'>Pozostałe</div>
                            <ul className='url-el'>
                                <li>Słownik</li>
                                <li>Wyceń nieruchomość</li>
                                <li>Do pobrania</li>
                                <a href='mapa-strony' alt='mapa strony' target='_blank'><li>Mapa strony</li></a>
                            </ul>
                        </div>

                        <div className='col-list'>
                            <div className='url-title'>Nasze osady</div>
                            <ul className='url-el'>
                                <li>Osada Srebrna Góra</li>
                                <li>Osada Wzgórza Kaszubskie</li>
                                <li>Osada Dolina Warty</li>
                                <li>Osada Jezioro Dołgie</li>
                                <li>Osada Jezioro Motława</li>
                                <li>Osada Kujawska Zatoka</li>
                                <li>Osada Słowińskie Dęby</li>
                                <li>Osada Lubuska Przystań</li>
                                <li>Osada Góry Bardzkie</li>
                                <li>Osada Leśna</li>
                                <li>Osada Potok</li>
                                <li>Osada Zacisze Narwi</li>
                                <li>Osada Góry Sowie</li>
                                <li>Osada Góry Sowie II</li>
                                <li>Osada Zdrojowa</li>
                                <li>Osada Świerkowy Bór</li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
            <SettlementRouter />
        </>
    )
}

export default Sitemap;