import React from 'react';
import { Formik, Field, Form } from 'formik';
import '../styles/contact-form-styles.scss';
import icGuard from '../assets/icons/contact/ic-guard.png'
import icProtect from '../assets/icons/contact/ic-protect.png'

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

// ----------------------------------------------------------------------

function ContactForm() {
    return (
        <div className="col-md-6 contact-form">
            <h4 className='contact-form-title'>Napisz do nas</h4>
            <Formik
                initialValues={{
                    toggle: false,
                    checked: [],
                }}
                onSubmit={async (values) => {
                    await sleep(500);
                    alert(JSON.stringify(values, null, 2));
                }}
            >
                {({ values }) => (
                    <Form>
                        <label className='form-question1'>W jakiej sprawie chcesz się skontaktować?</label>
                        <div role="group" aria-labelledby="checkbox-group1" className='form-answers1'>
                            <label>
                                <Field type="radio" name="buy" value="One" className="radioCheck" />
                                Chce kupić działkę
                            </label>
                            <label>
                                <Field type="radio" name="sell" value="Two" />
                                Chce sprzedać grunt
                            </label>
                            <label>
                                <Field type="radio" name="other" value="Three" />
                                Inne
                            </label>
                        </div>


                        <label className='form-question2'>Czy jesteś naszym klientem?</label>
                        <div role="group" aria-labelledby="checkbox-group2" className='form-answers2'>
                            <label>
                                <Field type="radio" name="checked" value="One" />
                                Tak
                            </label>
                            <label>
                                <Field type="radio" name="checked" value="Two" />
                                Nie
                            </label>
                        </div>
                        <br />

                        <label htmlFor="name">Twoje imię:</label><br />
                        <input type="text" name="name" placeholder="Twoje imię" className="inputStyles" /><br /><br />

                        <label htmlFor="tel">Numer telefonu:</label><br />
                        <input type="text" name="tel" placeholder="Numer telefonu" className="inputStyles" /><br /><br />

                        <label htmlFor="email">Adres e-mail:</label><br />
                        <input type="text" name="email" placeholder="twoj@email.com" className="inputStyles" /><br /><br />

                        <label htmlFor="msg">Twoja wiadomość:</label><br />
                        <textarea name="msg" placeholder="Wpisz swoją wiadomość..." className="inputStyles inputAreaStyles" /><br /><br />

                        <label className="inputDescSmall">
                            <Field type="checkbox" name="agreement" />
                            Wyrażam zgodę na przetwarzanie danych osobowych. Czytaj więcej. *
                        </label>

                        <label className="inputDescSmall">
                            <Field type="checkbox" name="newsletter" />
                            Chcę dostawać newsletter na podany adres e-mail.
                        </label>

                        <br />
                        <button type="submit" className="btnContact">Wyślij wiadomość</button>
                    </Form>
                )}
            </Formik>

            <div className="additionalInfo">
                <div>
                    <img src={icProtect} alt="Ochrona" />
                    <p>Nie przekazujemy Twoich danych osobom trzecim</p>
                </div>
                <div>
                    <img src={icGuard} alt="Zabezpieczenie" />
                    <p>Skontaktujemy się wyłącznie w celu przedstawienia oferty</p>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;


// <label>
// <Field type="checkbox" name="toggle" />
// {`${values.toggle}`}
// </label>