import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
// static elements
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
// pages
import Home from './pages/Home';
import OurSettlements from './pages/OurSettlements';
import AboutUs from './pages/AboutUs';
import PurchasingProcess from './pages/PurchasingProcess';
import Article from './pages/Article';
import Career from './pages/Career';
import Contact from './pages/Contact';
import SellGround from './pages/SellGround';

import Dictionary from './pages/Dictionary';
import Download from './pages/Download';
import Sitemap from './pages/Sitemap';
import Policy from './pages/Policy';

import Error404 from './pages/Error404';
// styles
// import './styles/App.css';
import './App.css';
import 'normalize.css';
import './index.scss';

// ----------------------------------------------------------------------

function App() {

  return (
    <div className="zi">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="nasze-osady" element={<OurSettlements />} />
          <Route path="o-nas" element={<AboutUs />} />
          <Route path="proces-zakupu" element={<PurchasingProcess />} />
          <Route path="czytelnia" element={<Article />} />
          <Route path="kariera" element={<Career />} />
          <Route path="kontakt" element={<Contact />} />
          <Route path="sprzedaj-grunt" element={<SellGround />} />

          <Route path="slownik" element={<Dictionary />} />
          <Route path="do-pobrania" element={<Download />} />
          <Route path="mapa-strony" element={<Sitemap />} />
          <Route path="polityka-prywatnosci" element={<Policy />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;

