import React from 'react';
import '../styles/hero-home-styles.scss';
// [assets]
import iconArrow from '../assets/images/iconArrow.png';
import { ReactComponent as IcStars } from '../assets/icons/ic-stars.svg';
import TitleDir from '../components/TitleDir';
import odwLogo from '../assets/images/contact/odw-logo.png';
import ojdLogo from '../assets/images/contact/ojd-logo.png';
import okzLogo from '../assets/images/contact/okz-logo.png';
import olpLogo from '../assets/images/contact/olp-logo.png';
import osdLogo from '../assets/images/contact/osd-logo.png';
import osgLogo from '../assets/images/contact/osg-logo.png';
import owkLogo from '../assets/images/contact/owk-logo.png';
import ogbLogo from '../assets/images/contact/ogb-logo.png';
import gridTiles from '../assets/images/home/grid-tiles.png';
import steps5 from '../assets/images/home/steps5.png';

// ----------------------------------------------------------------------

function HeroHome({ osdRef, owkRef, ojdRef, okzRef, olpRef, odwRef, osgRef, ogbRef, ojmRef, oleRef, oznRef, osbRef, ogsRef, ogs2Ref, ozdRef, opoRef }) {

    return (
        <>
            <div className='home-container'>
                <div className='hero-section-container'>
                    <div className='left-hs'>
                        <h1 className='title-cta-hero'>Wybierz działkę blisko natury pod Twój drugi dom</h1>
                        <p className='hero-description'>Posiadamy w swojej ofercie <span className='white-text'>8 osad</span>. Ponad <span className='white-text'>500 sprzedanych działek</span> w <span className='white-text'>16   lokalizacjach.</span></p>
                        <div className='btn-check-funnel' onClick={() => osgRef.current.scrollIntoView()}>
                            <span className='cta-text'>ZNAJDŹ DZIAŁKĘ DLA SIEBIE</span>
                            <img src={iconArrow} width='12' height='12' alt='strzałka'></img>
                        </div>
                        <div className='rating'>
                            <IcStars />
                            <p className="desc-rating">+500 zadowolonych klientów</p>
                        </div>

                        <img src={gridTiles} alt='osada' className='video-placeholder'></img>


                    </div>

                </div>


            </div>


            <div className='settlement-slider-cont'>
                <div className='settlement-section-cont'>
                    <p className='header-title'>Wybierz działkę blisko natury w jednej z naszych osad:</p>
                    <div className="settlement-selector">
                        <img src={osgLogo} alt="Osada Srebrna Góra" />
                        <img src={odwLogo} alt="Osada Dolina Warty" />
                        <img src={osdLogo} alt="Osada Słowińskie Dęby" />
                        <img src={owkLogo} alt="Osada Wzgórza Kaszubskie" />
                        <img src={olpLogo} alt="Osada Lubuska Przystań" />
                        <img src={okzLogo} alt="Osada Kujawska Zatoka" />
                        <img src={ojdLogo} alt="Osada Jezioro Dołgie" />
                        <img src={ogbLogo} alt="Osada Góry Bardzkie" />
                    </div>


                    <div className='found-plot'>



                        <div className='career-container2 bootstrap-cont'>
                            <div className='career-section-container'>
                                <div className="container">
                                    <div className="row underline">
                                        <div className="col-md-6 pad">
                                            <TitleDir>Znajdź działkę _</TitleDir>
                                            <p className="contact-title">5 prostych kroków do Twojej działki</p>

                                            <p className='main-description'>Eu eu elementum phasellus tempor quisque turpis. Semper enim lectus volutpat nibh turpis ut. Nunc viverra tincidunt lacinia sit. Mauris egestas vel amet arcu eleifend dolor quis. Neque dictum elit rhoncus.</p>

                                            <div className="buy-ground" >
                                                <div>Znajdź działkę dla siebie</div>
                                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                                            </div>
                                        </div>

                                        <div className="col-md-6 pad">
                                            <img src={steps5} alt="5 kroków" className="steps5img" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='discover-settlement bootstrap-cont'>
                            <div className='discover-section-container'>
                                <div className="container">
                                    <div className="row underline">
                                        <div className="col-md-12 pad">
                                            <div classname="col center-title">
                                                <div className="nasz">
                                                    <TitleDir>NASZE OSADY _</TitleDir>
                                                </div>
                                                <p className="title-discover">Odkryj nasze Osady</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>

                </div>
            </div>

        </>
    )
}

export default HeroHome;