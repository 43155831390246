import React from 'react';
import '../styles/download-list-styles.scss';
import downloadButton from '../assets/images/download-btn.png';
import documentIcon from '../assets/images/document-icon.png';

// ----------------------------------------------------------------------

function DownloadList() {
    return (
        <div className="dl-container" >
            <div className='dl-section-container'>

                <div className='dl-category-name'>Pliki graficzne</div>
                <div className="table-container" role="table" aria-label="Destinations">
                    <div className="flex-table header" role="rowgroup">
                        <div className="flex-row first" role="columnheader">Nazwa</div>
                        <div className="flex-row" role="columnheader">Ostatnie zmiany</div>
                        <div className="flex-row" role="columnheader">Rozmiar pliku</div>
                        <div className="flex-row" role="columnheader">Typ pliku</div>
                        <div className="flex-row" role="columnheader"></div>
                    </div>
                    <div className="flex-table row" role="rowgroup">
                        <div className="flex-row first" role="cell"><img src={documentIcon} alt='Dokument' className='document-icon' />System Identyfikacji Wizualnej Ziemia Inwestycje</div>
                        <div className="flex-row date" role="cell">12 czerwca 2022</div>
                        <div className="flex-row size" role="cell">56 KB</div>
                        <div className="flex-row filetype" role="cell">.pdf</div>
                        <div className="flex-row" role="cell"><img src={downloadButton} alt='Pobierz' className='download-icon' />POBIERZ PLIK</div>
                    </div>
                    <div className="flex-table row" role="rowgroup">
                        <div className="flex-row first" role="cell"><img src={documentIcon} alt='Dokument' className='document-icon' />Logo Ziemia Inwestycje</div>
                        <div className="flex-row date" role="cell">19 stycznia 2022</div>
                        <div className="flex-row size" role="cell">32 KB</div>
                        <div className="flex-row filetype" role="cell">.ai</div>
                        <div className="flex-row" role="cell"><img src={downloadButton} alt='Pobierz' className='download-icon' />POBIERZ PLIK</div>
                    </div>
                    <div className="flex-table row" role="rowgroup">
                        <div className="flex-row first" role="cell"><img src={documentIcon} alt='Dokument' className='document-icon' />Zdjęcia Osady Wzgórza Kaszubskie</div>
                        <div className="flex-row date" role="cell">20 listopad 2021</div>
                        <div className="flex-row size" role="cell">9 MB</div>
                        <div className="flex-row filetype" role="cell">.zip</div>
                        <div className="flex-row" role="cell"><img src={downloadButton} alt='Pobierz' className='download-icon' />POBIERZ PLIK</div>
                    </div>
                    <div className="flex-table row" role="rowgroup">
                        <div className="flex-row first" role="cell"><img src={documentIcon} alt='Dokument' className='document-icon' />Zdjęcia Osady Srebrna Góra</div>
                        <div className="flex-row date" role="cell">25 październik 2021</div>
                        <div className="flex-row size" role="cell">4 MB</div>
                        <div className="flex-row filetype" role="cell">.zip</div>
                        <div className="flex-row" role="cell"><img src={downloadButton} alt='Pobierz' className='download-icon' />POBIERZ PLIK</div>
                    </div>
                </div>

                <div className='dl-category-name'>Dokumenty formalne</div>
                <div className="table-container" role="table" aria-label="Destinations">
                    <div className="flex-table header" role="rowgroup">
                        <div className="flex-row first" role="columnheader">Nazwa</div>
                        <div className="flex-row" role="columnheader">Ostatnie zmiany</div>
                        <div className="flex-row" role="columnheader">Rozmiar pliku</div>
                        <div className="flex-row" role="columnheader">Typ pliku</div>
                        <div className="flex-row" role="columnheader"></div>
                    </div>
                    <div className="flex-table row" role="rowgroup">
                        <div className="flex-row first" role="cell"><img src={documentIcon} alt='Dokument' className='document-icon' />Polityka prywatności</div>
                        <div className="flex-row date" role="cell">12 czerwca 2022</div>
                        <div className="flex-row size" role="cell">840 KB</div>
                        <div className="flex-row filetype" role="cell">.pdf</div>
                        <div className="flex-row" role="cell"><img src={downloadButton} alt='Pobierz' className='download-icon' />POBIERZ PLIK</div>
                    </div>
                    <div className="flex-table row" role="rowgroup">
                        <div className="flex-row first" role="cell"><img src={documentIcon} alt='Dokument' className='document-icon' />Ujawniony podziału osady Osada Góry Sowie II</div>
                        <div className="flex-row date" role="cell">19 stycznia 2022</div>
                        <div className="flex-row size" role="cell">2 MB</div>
                        <div className="flex-row filetype" role="cell">.zip</div>
                        <div className="flex-row" role="cell"><img src={downloadButton} alt='Pobierz' className='download-icon' />POBIERZ PLIK</div>
                    </div>
                </div>

                <div className='dl-category-name'>Materiały reklamowe</div>
                <div className="table-container" role="table" aria-label="Destinations">
                    <div className="flex-table header" role="rowgroup">
                        <div className="flex-row first" role="columnheader">Nazwa</div>
                        <div className="flex-row" role="columnheader">Ostatnie zmiany</div>
                        <div className="flex-row" role="columnheader">Rozmiar pliku</div>
                        <div className="flex-row" role="columnheader">Typ pliku</div>
                        <div className="flex-row" role="columnheader"></div>
                    </div>
                    <div className="flex-table row" role="rowgroup">
                        <div className="flex-row first" role="cell"><img src={documentIcon} alt='Dokument' className='document-icon' />Katalog reklamowy 2022</div>
                        <div className="flex-row date" role="cell">25 stycznia 2022</div>
                        <div className="flex-row size" role="cell">8 MB</div>
                        <div className="flex-row filetype" role="cell">.zip</div>
                        <div className="flex-row" role="cell"><img src={downloadButton} alt='Pobierz' className='download-icon' />POBIERZ PLIK</div>
                    </div>
                    <div className="flex-table row" role="rowgroup">
                        <div className="flex-row first" role="cell"><img src={documentIcon} alt='Dokument' className='document-icon' />Broszura - Osada Słowińskie Dęby</div>
                        <div className="flex-row date" role="cell">22 wrzesień 2021</div>
                        <div className="flex-row size" role="cell">4 MB</div>
                        <div className="flex-row filetype" role="cell">.pdf</div>
                        <div className="flex-row" role="cell"><img src={downloadButton} alt='Pobierz' className='download-icon' />POBIERZ PLIK</div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DownloadList