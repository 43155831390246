import React from 'react';
import '../styles/settlement-map-styles.scss';
// assets
import osgPhoto from '../assets/images/osg-photo.png';
import owkPhoto from '../assets/images/owk-photo.png';
import osdPhoto from '../assets/images/osd-photo.png';
import okzPhoto from '../assets/images/okz-photo.png';
import ojdPhoto from '../assets/images/ojd-photo.png';
import odwPhoto from '../assets/images/odw-photo.png';
import olpPhoto from '../assets/images/olp-photo.png';
import ogbPhoto from '../assets/images/ogb-photo.png';
import oznPhoto from '../assets/images/ozn-photo.png';
import olePhoto from '../assets/images/ole-photo.png';
import osbPhoto from '../assets/images/osb-photo.png';
import ogsPhoto from '../assets/images/ogs-photo.png';
import ogsPhoto2 from '../assets/images/ogs-photo2.png';
import ozdPhoto from '../assets/images/ozd-photo.png';
import opoPhoto from '../assets/images/opo-photo.png';
import ojmPhoto from '../assets/images/ojm-photo.png';
import osgLogoWhite from '../assets/images/logo/osg-logo-white.png';
import owkLogoWhite from '../assets/images/logo/owk-logo-white.png';
import osdLogoWhite from '../assets/images/logo/osd-logo-white.png';
import okzLogoWhite from '../assets/images/logo/okz-logo-white.png';
import ojdLogoWhite from '../assets/images/logo/ojd-logo-white.png';
import odwLogoWhite from '../assets/images/logo/odw-logo-white.png';
import olpLogoWhite from '../assets/images/logo/olp-logo-white.png';
import ogbLogoWhite from '../assets/images/logo/ogb-logo-white.png';
import labelInSale from '../assets/images/label-insale.png';
import labelSaleDone from '../assets/images/label-saledone.png';
import lineBlack from '../assets/images/line-black.png';
import smallIconMountain from '../assets/images/small-icon-mountain.png';
import smallIconSea from '../assets/images/small-icon-sea.png';
import icArea from '../assets/images/ic-area.png';
import icPrice from '../assets/images/ic-price.png';
import iconArrow from '../assets/images/iconArrow.png';
import iconArrowDark from '../assets/images/iconArrowDark.png';
import vw from '../assets/images/gif/vw.gif';

// ----------------------------------------------------------------------

function SettlementMap({ osdRef, owkRef, ojdRef, okzRef, olpRef, odwRef, osgRef, ogbRef, ojmRef, oleRef, oznRef, osbRef, ogsRef, ogs2Ref, ozdRef, opoRef }) {

    return (
        <>
            {/* colony box */}
            <div className="smContainer" ref={osgRef}>
                <div className='settlement-section-container'>
                    <div className='photoSs'>
                        <img src={osgPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                        <img src={osgLogoWhite} alt="Srebrna Góra" className='cAbsolute logoWhiteLeft' />
                        <a href='https://spacery.ziemiainwestycje.pl/osada-srebrna-gora/' target='_blank' rel='noreferrer'><img src={vw} alt="Wirtualny spacer" className='cAbsolute vwOsg' /></a>
                    </div>
                    <div className='descriptionSs'>
                        <div className='content'>
                            <img src={labelInSale} alt='line' />
                            <h1>Osada Srebrna Góra</h1>
                            <span><img src={smallIconMountain} alt='mountain' className='mountainPosition' /> Góry Bardzkie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />
                            <p className="description">Pomiędzy Górami Sowimi a Kotliną Kłodzką, pośród szczytów, lasów i strumyków stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <div className='infoContainer'>
                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icArea} alt='resize' />Metraz od:
                                    </div>
                                    <div className='infoSize'>
                                        670 m<sup>2</sup>
                                    </div>
                                </div>

                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icPrice} alt='resize' />   Cena od: <span className="oldPrice">113 050 zł</span>
                                    </div>
                                    <div className='infoPrice'>
                                        104 750 zł<sup></sup>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <a href="{NULL}"><div className='btn-primary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={owkRef}>
                <div className='settlement-section-container'>
                    <div className='descriptionSs'>
                        <div className='content'>
                            <img src={labelInSale} alt='line' />
                            <h1>Osada Wzgórza Kaszubskie</h1>
                            <span><img src={smallIconSea} alt='sea' /> Pomorze</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <div className='infoContainer'>
                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icArea} alt='resize' />Metraz od:
                                    </div>
                                    <div className='infoSize'>
                                        35 m<sup>2</sup>
                                    </div>
                                </div>

                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icPrice} alt='resize' />   Cena od:
                                    </div>
                                    <div className='infoPrice' style={{ color: 'black' }}>
                                        369 000 zł<sup></sup>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <a href="{NULL}"><div className='btn-primary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                    <div className='photoSs'>
                        <img src={owkPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                        <img src={owkLogoWhite} alt="Wzgórza Kaszubskie" className='cAbsolute logoWhiteRight' />
                        <a href='https://spacery.ziemiainwestycje.pl/osada-wzgorza-kaszubskie/' target='_blank' rel='noreferrer'><img src={vw} alt="Wirtualny spacer" className='cAbsolute vwOwk' /></a>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={osdRef}>
                <div className='settlement-section-container'>
                    <div className='photoSs'>
                        <img src={osdPhoto} alt='Osada Słowińskie Dęby' width="590px" className='photoFunnel' />
                        <img src={osdLogoWhite} alt="Słowińskie Dęby" className='cAbsolute logoWhiteLeft' />
                        <a href='https://spacery.ziemiainwestycje.pl/osada-slowinskie-deby' target='_blank' rel='noreferrer'><img src={vw} alt="Wirtualny spacer" className='cAbsolute vwOsd' /></a>
                    </div>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelInSale} alt='line' />

                            <h1>Osada Słowińskie Dęby</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pomorze</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pośród dzikich plaż, dębowych lasów Słowińskiego Parku Narodowego  stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <div className='infoContainer'>
                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icArea} alt='resize' />Metraz od:
                                    </div>
                                    <div className='infoSize'>
                                        800 m<sup>2</sup>
                                    </div>
                                </div>

                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icPrice} alt='resize' />   Cena od: <span className="oldPrice">92 070 zł</span>
                                    </div>
                                    <div className='infoPrice'>
                                        85 034 zł<sup></sup>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <a href="{NULL}"><div className='btn-primary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={okzRef}>
                <div className='settlement-section-container'>
                    <div className='descriptionSs'>
                        <div className='content'>
                            <img src={labelInSale} alt='line' />
                            <h1>Osada Kujawska Zatoka</h1>
                            <span><img src={smallIconSea} alt='sea' /> Pomorze</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <div className='infoContainer'>
                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icArea} alt='resize' />Metraz od:
                                    </div>
                                    <div className='infoSize'>
                                        800 m<sup>2</sup>
                                    </div>
                                </div>

                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icPrice} alt='resize' />   Cena od:
                                    </div>
                                    <div className='infoPrice' style={{ color: 'black' }}>
                                        79 000 zł<sup></sup>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <a href="{NULL}"><div className='btn-primary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                    <div className='photoSs'>
                        <img src={okzPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                        <img src={okzLogoWhite} alt="Wzgórza Kaszubskie" className='cAbsolute logoWhiteRight' />
                        {/* <img src={vw} alt="Wirtualny spacer" className='cAbsolute vwOwk' /> */}
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={ojdRef}>
                <div className='settlement-section-container'>
                    <div className='photoSs'>
                        <img src={ojdPhoto} alt='Osada Słowińskie Dęby' width="590px" className='photoFunnel' />
                        <img src={ojdLogoWhite} alt="Słowińskie Dęby" className='cAbsolute logoWhiteLeft' />
                        {/* <img src={vw} alt="Wirtualny spacer" className='cAbsolute vwOsd' /> */}
                    </div>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelInSale} alt='line' />

                            <h1>Osada Jezioro Dołgie</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pomorze</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pośród dzikich plaż, dębowych lasów Słowińskiego Parku Narodowego  stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <div className='infoContainer'>
                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icArea} alt='resize' />Metraz od:
                                    </div>
                                    <div className='infoSize'>
                                        800 m<sup>2</sup>
                                    </div>
                                </div>

                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icPrice} alt='resize' />   Cena od: <span className="oldPrice">92 070 zł</span>
                                    </div>
                                    <div className='infoPrice'>
                                        85 034 zł<sup></sup>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <a href="{NULL}"><div className='btn-primary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={odwRef}>
                <div className='settlement-section-container'>
                    <div className='descriptionSs'>
                        <div className='content'>
                            <img src={labelInSale} alt='line' />
                            <h1>Osada Dolina Warty</h1>
                            <span><img src={smallIconSea} alt='sea' /> Pomorze</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <div className='infoContainer'>
                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icArea} alt='resize' />Metraz od:
                                    </div>
                                    <div className='infoSize'>
                                        800 m<sup>2</sup>
                                    </div>
                                </div>

                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icPrice} alt='resize' />   Cena od:
                                    </div>
                                    <div className='infoPrice' style={{ color: 'black' }}>
                                        79 000 zł<sup></sup>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <a href="{NULL}"><div className='btn-primary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                    <div className='photoSs'>
                        <img src={odwPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                        <img src={odwLogoWhite} alt="Wzgórza Kaszubskie" className='cAbsolute logoWhiteRight' />
                        {/* <img src={vw} alt="Wirtualny spacer" className='cAbsolute vwOwk' /> */}
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={olpRef}>
                <div className='settlement-section-container'>
                    <div className='photoSs'>
                        <img src={olpPhoto} alt='Osada Słowińskie Dęby' width="590px" className='photoFunnel' />
                        <img src={olpLogoWhite} alt="Słowińskie Dęby" className='cAbsolute logoWhiteLeft' />
                        {/* <img src={vw} alt="Wirtualny spacer" className='cAbsolute vwOsd' /> */}
                    </div>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelInSale} alt='line' />

                            <h1>Osada Lubuska Przystań</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pomorze</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pośród dzikich plaż, dębowych lasów Słowińskiego Parku Narodowego  stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <div className='infoContainer'>
                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icArea} alt='resize' />Metraz od:
                                    </div>
                                    <div className='infoSize'>
                                        800 m<sup>2</sup>
                                    </div>
                                </div>

                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icPrice} alt='resize' />   Cena od: <span className="oldPrice">92 070 zł</span>
                                    </div>
                                    <div className='infoPrice'>
                                        85 034 zł<sup></sup>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <a href="{NULL}"><div className='btn-primary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={ogbRef}>
                <div className='settlement-section-container'>
                    <div className='descriptionSs'>
                        <div className='content'>
                            <img src={labelInSale} alt='line' />
                            <h1>Osada Góry Bardzkie</h1>
                            <span><img src={smallIconSea} alt='sea' /> Pomorze</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <div className='infoContainer'>
                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icArea} alt='resize' />Metraz od:
                                    </div>
                                    <div className='infoSize'>
                                        800 m<sup>2</sup>
                                    </div>
                                </div>

                                <div className='infoBox'>
                                    <div className='infoLabel'>
                                        <img src={icPrice} alt='resize' />   Cena od:
                                    </div>
                                    <div className='infoPrice' style={{ color: 'black' }}>
                                        79 000 zł<sup></sup>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <a href="{NULL}"><div className='btn-primary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                    <div className='photoSs'>
                        <img src={ogbPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                        <img src={ogbLogoWhite} alt="Wzgórza Kaszubskie" className='cAbsolute logoWhiteRight' />
                        {/* <img src={vw} alt="Wirtualny spacer" className='cAbsolute vwOwk' /> */}
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={opoRef}>
                <div className='settlement-section-container'>
                    <div className='photoSs'>
                        <img src={opoPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                    </div>
                    <div className='descriptionSs'>
                        <div className='content'>
                            <img src={labelSaleDone} alt='line' />
                            <h1>Osada Potok</h1>
                            <span><img src={smallIconSea} alt='sea' /> Pojezierze Iławskie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />
                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <br /><br />
                            <a href="{NULL}"><div className='btn-secondary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={ojmRef}>
                <div className='settlement-section-container'>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelSaleDone} alt='line' />

                            <h1>Osada Jezioro Motława</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pojezierze Iławskie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <br /><br />
                            <a href="{NULL}"><div className='btn-secondary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                    <div className='photoSs'>
                        <img src={ojmPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={oznRef}>
                <div className='settlement-section-container'>
                    <div className='photoSs'>
                        <img src={oznPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                    </div>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelSaleDone} alt='line' />

                            <h1>Osada Zacisze Narwi</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pojezierze Iławskie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <br /><br />
                            <a href="{NULL}"><div className='btn-secondary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={oleRef}>
                <div className='settlement-section-container'>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelSaleDone} alt='line' />

                            <h1>Osada Leśna</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pojezierze Iławskie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <br /><br />
                            <a href="{NULL}"><div className='btn-secondary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                    <div className='photoSs'>
                        <img src={olePhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={osbRef}>
                <div className='settlement-section-container'>
                    <div className='photoSs'>
                        <img src={osbPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                    </div>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelSaleDone} alt='line' />

                            <h1>Osada Świerkowy Bór</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pojezierze Iławskie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <br /><br />
                            <a href="{NULL}"><div className='btn-secondary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={ogsRef}>
                <div className='settlement-section-container'>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelSaleDone} alt='line' />

                            <h1>Osada Góry Sowie</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pojezierze Iławskie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <br /><br />
                            <a href="{NULL}"><div className='btn-secondary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                    <div className='photoSs'>
                        <img src={ogsPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={ogs2Ref}>
                <div className='settlement-section-container'>
                    <div className='photoSs'>
                        <img src={ogsPhoto2} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                    </div>
                    <div className='descriptionSs'>
                        <div className='content'>

                            <img src={labelSaleDone} alt='line' />

                            <h1>Osada Góry Sowie II</h1>

                            <span><img src={smallIconSea} alt='sea' /> Pojezierze Iławskie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <br /><br />
                            <a href="{NULL}"><div className='btn-secondary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div >

            {/* colony box */}
            <div className="smContainer" ref={ozdRef}>
                <div className='settlement-section-container'>
                    <div className='descriptionSs'>
                        <div className='content'>
                            <img src={labelSaleDone} alt='line' />
                            <h1>Osada Zdrojowa</h1>
                            <span><img src={smallIconSea} alt='sea' /> Pojezierze Iławskie</span>
                            <br /><br />
                            <img src={lineBlack} alt='line' />

                            <p className="description">Pomiędzy Kaszubami a Borami Tucholskimi, pośród wzgórz, lasów i łąk stworzyliśmy miejsce pod Twój drugi dom!</p>
                            <br />
                            <br /><br />
                            <a href="{NULL}"><div className='btn-secondary'>
                                <span>POZNAJ OSADĘ</span>
                                <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
                            </div></a>
                        </div>
                    </div>
                    <div className='photoSs'>
                        <img src={ozdPhoto} alt='Osada Srebrna Góra' width="590px" className='photoFunnel' />
                    </div>
                </div>
            </div >


        </>
    )
}

export default SettlementMap;