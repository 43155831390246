import React from 'react';
import HeroBlank from '../components/HeroBlank';
import DownloadList from '../components/DownloadList';
import SettlementRouter from '../components/SettlementRouter';

// ----------------------------------------------------------------------

function Download() {

    const title = 'Pliki do pobrania';
    const description = `Lista plików które mozna pobrać z naszej strony. `;

    return (
        <>
            <HeroBlank title={title} description={description} />
            <DownloadList />
            <SettlementRouter />
        </>
    )
}

export default Download;