import React, { useState } from 'react';
import '../styles/accordion-styles.scss';
import { Collapse } from 'reactstrap';
import '../styles/libs/bootstrap.min.scss';

// ---------------------------------------------------------------------

// https://freefrontend.com/bootstrap-accordions/
// https://github.com/arslanah99/accordion-tutorial-for-youtube

// const accordionData = [{
//     title: 'Gdzie położone są Osady?',
//     text: 'Nasze osady zlokalizowane są w rejonach atrakcyjnych turystycznie i wypoczynkowo. Jednak ich zaciszne położenie, często w pobliżu lasów, pozwala zachować kameralnych charakter i cieszyć się spokojem. Osady są idealnym miejscem na drugi dom!'
// },
// {
//     title: 'Jaki rodzaj działek sprzedajemy?',
//     text: 'Nasze Osady nadają się pod zabudowę: mieszkaniową jednorodzinną lub rekreacyjną, a czasem istnieje także możliwość prowadzenia działalności turystycznej.'
// }, {
//     title: 'Jakie media będę mieć na działce?',
//     text: 'Gwarantujemy dostęp do sieci energetycznej oraz sieci wodociągowej, a także budowę utwardzonych dróg wewnętrznych, które zapewnią komfortowy dojazd do Twojej działki.'
// },
// {
//     title: 'Jaką mam pewność, że media będą w terminie w jakim Państwo się deklarują?',
//     text: 'Terminy mediów są zagwarantowane odpowiednim zapisem w akcie notarialnym.'
// },
// {
//     title: 'Czy Państwo są pośrednikami?',
//     text: 'Nie. Jesteśmy właścicielami działek, a dzięki temu, że nabywają Państwo działkę od firmy, nie płacą Państwo standardowego podatku od czynności cywilnoprawnych w wysokości 2% wartości nieruchomości.'
// }]

function AccordionFaq(props) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="container">
            <div className="row">
                <div className="col-6 col-sm-6"></div>
                <div className="col-6 col-sm-6">
                    <div className="wrapper">
                        <div className={isOpen ? "open" : ""} onClick={toggle}>
                            <div className="title-contact">
                                {props.title} <span className={isOpen ? "arrow-dw" : "arrow"}>
                                    <i className="fa fa-angle-down"></i></span>
                            </div>
                        </div>
                        <Collapse isOpen={isOpen} >
                            <p className="marginasd">{props.text}</p>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    )
}

// export { accordionData };
export default AccordionFaq;