import React from 'react';
import '../styles/hero-section-styles.scss';
import Lottie from 'react-lottie';
// [assets]
import iconArrow from '../assets/images/iconArrow.png';
import videoPlaceholder from '../assets/images/video-placeholder.png';
import lineWhite from '../assets/images/line-white.png';
import { ReactComponent as MapSvg } from '../assets/images/map.svg';
import lottiePin from '../assets/lotties/lottie-pin.json';
import tooltipMap from '../assets/images/tooltip-map.png';
// [assets] symbol
import icForest from '../assets/images/symbol/ic-forest.svg';
import icSeaside from '../assets/images/symbol/ic-seaside.svg';
// [assets] photo in boxes
import osgPhoto from '../assets/images/osg-photo.png';
import owkPhoto from '../assets/images/owk-photo.png';
import osdPhoto from '../assets/images/osd-photo.png';
import okzPhoto from '../assets/images/okz-photo.png';
import ojdPhoto from '../assets/images/ojd-photo.png';
import odwPhoto from '../assets/images/odw-photo.png';
import olpPhoto from '../assets/images/olp-photo.png';
import ogbPhoto from '../assets/images/ogb-photo.png';
import oznPhoto from '../assets/images/ozn-photo.png';
import olePhoto from '../assets/images/ole-photo.png';
import osbPhoto from '../assets/images/osb-photo.png';
import ogsPhoto from '../assets/images/ogs-photo.png';
import ogsPhoto2 from '../assets/images/ogs-photo2.png';
import ozdPhoto from '../assets/images/ozd-photo.png';
import opoPhoto from '../assets/images/opo-photo.png';
import ojmPhoto from '../assets/images/ojm-photo.png';

// ----------------------------------------------------------------------

function HeroSection({ osdRef, owkRef, ojdRef, okzRef, olpRef, odwRef, osgRef, ogbRef, ojmRef, oleRef, oznRef, osbRef, ogsRef, ogs2Ref, ozdRef, opoRef }) {

    // lotties global config
    const pinOptions = {
        loop: true,
        autoplay: true,
        animationData: lottiePin,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
    };

    return (
        <div className='hs-container'>
            <div className='hero-section-container'>
                <div className='left-hs'>
                    <p className='title-hero'>ODKRYJ NASZE OSADY _</p>
                    <h1 className='title-cta-hero'>Wybierz osadę</h1>
                    <p className='hero-description'>Posiadamy w swojej ofercie <span className='white-text'>8 osad</span>. Ponad <span className='white-text'>500 <br />sprzedanych działek</span> w <span className='white-text'>16   lokalizacjach.</span></p>
                    <div className='btn-check-funnel' onClick={() => osgRef.current.scrollIntoView()}>
                        <span className='cta-text'>SPRAWDŹ NASZE OSADY</span>
                        <img src={iconArrow} width='12' height='12' alt='strzałka'></img>
                    </div>
                    <img src={videoPlaceholder} alt='osada' className='video-placeholder'></img>
                    <div className='text-placeholder'>
                        <img src={lineWhite} alt='line' width='140px' />
                        <p className='hero-description-settlement'>Osady w najbardziej atrakcyjnych regionach Polski: Sudety, Pomorze, Beskid Niski, Dolina Narwi, Kotlina Kłodzka.</p>
                    </div>

                </div>
                <div className='right-hs'>
                    <div className='map-container'>
                        <MapSvg className='map-svg' />
                        <img src={tooltipMap} alt='Wybierz osadę' className='tooltip-map-info' />

                        <div className='pin active-pin osd-pin tooltip' onClick={() => osdRef.current.scrollIntoView()}>
                            <div className='tooltip-text'>
                                <img src={osdPhoto} alt='Osada Słowińskie Dęby' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Słowińskie Dęby</p>
                                    <span className='region-name'><img src={icSeaside} alt='Pomorze' className='region-icon' />Pomorze</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle' />
                        </div>

                        <div className='pin active-pin owk-pin tooltip' onClick={() => owkRef.current.scrollIntoView()}>
                            <div className='tooltip-text'>
                                <img src={owkPhoto} alt='Osada Wzgórza Kaszubskie' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Wzgórza Kaszubskie</p>
                                    <span className='region-name'><img src={icSeaside} alt='Pomorze' className='region-icon' />Pomorze</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle' />
                        </div>

                        <div className='pin active-pin ojd-pin tooltip' onClick={() => ojdRef.current.scrollIntoView()}>
                            <div className='tooltip-text'>
                                <img src={ojdPhoto} alt='Osada Jezioro Dołgie' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Jezioro Dołgie</p>
                                    <span className='region-name'><img src={icSeaside} alt='Pojezierze Drawskie' className='region-icon' />Pojezierze Drawskie</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle' />
                        </div>

                        <div className='pin active-pin okz-pin tooltip' onClick={() => okzRef.current.scrollIntoView()}>
                            <div className='tooltip-text'>
                                <img src={okzPhoto} alt='Osada Lubuska Przystań' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Kujawska Zatoka</p>
                                    <span className='region-name'><img src={icSeaside} alt='Pojezierze Brodnickie' className='region-icon' />Pojezierze Brodnickie</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle' />
                        </div>

                        <div className='pin active-pin olp-pin tooltip' onClick={() => olpRef.current.scrollIntoView()}>
                            <div className='tooltip-text'>
                                <img src={olpPhoto} alt='Osada Lubuska Przystań' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Lubuska Przystań</p>
                                    <span className='region-name'><img src={icSeaside} alt='Pojezierze Lubuskie' className='region-icon' />Pojezierze Lubuskie</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle' />
                        </div>

                        <div className='pin active-pin odw-pin tooltip' onClick={() => odwRef.current.scrollIntoView()}>
                            <div className='tooltip-text'>
                                <img src={odwPhoto} alt='Osada Dolina Warty' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Dolina Warty</p>
                                    <span className='region-name'><img src={icSeaside} alt='Pojezierze Lubuskie' className='region-icon' />Pojezierze Lubuskie</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle' />
                        </div>

                        <div className='pin active-pin osg-pin tooltip' onClick={() => osgRef.current.scrollIntoView()}>
                            <div className='tooltip-text'>
                                <img src={osgPhoto} alt='Osada Srebrna Góra' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Srebrna Góra</p>
                                    <span className='region-name'><img src={icSeaside} alt='Góry Bardzkie' className='region-icon' />Góry Bardzkie</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle' />
                        </div>

                        <div className='pin active-pin ogb-pin tooltip' onClick={() => ogbRef.current.scrollIntoView()}>
                            <div className='tooltip-text'>
                                <img src={ogbPhoto} alt='Osada Góry Bardzkie' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Góry Bardzkie</p>
                                    <span className='region-name'><img src={icSeaside} alt='Kotlina Kłodzka' className='region-icon' />Kotlina Kłodzka</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle' />
                        </div>

                        <div className='pin inactive-pin ojm-pin tooltip' onClick={() => ojmRef.current.scrollIntoView()}>
                            <div className='tooltip-text sold'>
                                <img src={ojmPhoto} alt='Osada Jezioro Motława' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Jezioro Motława</p>
                                    <span className='region-name'><img src={icSeaside} alt='Pojezierze Iławskie' className='region-icon' />Pojezierze Iławskie</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle triangle-inactive' />
                        </div>

                        <div className='pin inactive-pin ole-pin tooltip' onClick={() => oleRef.current.scrollIntoView()}>
                            <div className='tooltip-text sold'>
                                <img src={olePhoto} alt='Osada Leśna' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Leśna</p>
                                    <span className='region-name'><img src={icForest} alt='Dolina Narwi' className='region-icon' />Dolina Narwi</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle triangle-inactive' />
                        </div>

                        <div className='pin inactive-pin ozn-pin tooltip' onClick={() => oznRef.current.scrollIntoView()}>
                            <div className='tooltip-text sold'>
                                <img src={oznPhoto} alt='Osada Świerkowy Bór' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Zacisze Narwi</p>
                                    <span className='region-name'>
                                        <img src={icSeaside} alt='Dolina Narwi' className='region-icon' />Dolina Narwi
                                    </span>
                                </div>
                            </div>
                            <div className='tooltip-triangle triangle-inactive' />
                        </div>


                        <div className='pin inactive-pin osb-pin tooltip' onClick={() => osbRef.current.scrollIntoView()}>
                            <div className='tooltip-text sold'>
                                <img src={osbPhoto} alt='Osada Świerkowy Bór' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Świerkowy Bór</p>
                                    <span className='region-name'><img src={icSeaside} alt='Mazowiecki Park Krajobrazowy' className='region-icon' />Mazowiecki Park Krajobrazowy</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle triangle-inactive' />
                        </div>

                        <div className='pin inactive-pin ogs-pin tooltip' onClick={() => ogsRef.current.scrollIntoView()}>
                            <div className='tooltip-text sold'>
                                <img src={ogsPhoto} alt='Góry Sowie' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Góry Sowie</p>
                                    <span className='region-name'><img src={icSeaside} alt='Góry Sowie' className='region-icon' />Góry Sowie</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle triangle-inactive' />
                        </div>

                        <div className='pin inactive-pin ogs2-pin tooltip' onClick={() => ogs2Ref.current.scrollIntoView()}>
                            <div className='tooltip-text sold'>
                                <img src={ogsPhoto2} alt='Góry Sowie' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Góry Sowie II</p>
                                    <span className='region-name'><img src={icSeaside} alt='Góry Sowie' className='region-icon' />Góry Sowie</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle triangle-inactive' />
                        </div>

                        <div className='pin inactive-pin ozd-pin tooltip' onClick={() => ozdRef.current.scrollIntoView()}>
                            <div className='tooltip-text sold'>
                                <img src={ozdPhoto} alt='Zdrojowa' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Zdrojowa</p>
                                    <span className='region-name'><img src={icSeaside} alt='Kotlina Kłodzka' className='region-icon' />Kotlina Kłodzka</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle triangle-inactive' />
                        </div>

                        <div className='pin inactive-pin opo-pin tooltip' onClick={() => opoRef.current.scrollIntoView()}>
                            <div className='tooltip-text sold'>
                                <img src={opoPhoto} alt='Wzgórza Kaszubskie' className='miniatures-settlement'></img>
                                <div>
                                    <p className='title-settlement'>Osada Potok</p>
                                    <span className='region-name'><img src={icSeaside} alt='Beskid Niski' className='region-icon' />Beskid Niski</span>
                                </div>
                            </div>
                            <div className='tooltip-triangle triangle-inactive' />
                        </div>







                        {/* temp start */}
                        {/* https://www.30secondsofcode.org/react/s/tooltip */}
                        {/* <div
                            style={{ color: 'white', width: '200px', height: '100px' }}
                            onMouseEnter={() => { setShow(true); console.log('enter'); }}
                            onMouseLeave={() => { setShow(false); console.log('exit'); }}>{show} asd</div> */}
                        {/* temp end */}

                        <Lottie
                            options={pinOptions}
                            style={{
                                position: 'absolute',
                                left: '149px',
                                top: '6px',
                                width: '35px',
                                height: '35px',
                                // zIndex: 0,
                            }}
                            className='lottie-global'
                        />
                        <Lottie
                            options={pinOptions}
                            style={{
                                position: 'absolute',
                                left: '213px',
                                top: '67px',
                                width: '35px',
                                height: '35px',
                                // zIndex: 0,
                            }}
                            className='lottie-global'
                        />
                        <Lottie
                            options={pinOptions}
                            style={{
                                position: 'absolute',
                                left: '249px',
                                top: '111px',
                                width: '35px',
                                height: '35px',
                                // zIndex: 0,
                            }}
                            className='lottie-global'
                        />
                        <Lottie
                            options={pinOptions}
                            style={{
                                position: 'absolute',
                                left: '123px',
                                top: '75px',
                                width: '35px',
                                height: '35px',
                                // zIndex: 0,
                            }}
                            className='lottie-global'
                        />
                        <Lottie
                            options={pinOptions}
                            style={{
                                position: 'absolute',
                                left: '33px',
                                top: '199px',
                                width: '35px',
                                height: '35px',
                                // zIndex: 0,
                            }}
                            className='lottie-global'
                        />
                        <Lottie
                            options={pinOptions}
                            style={{
                                position: 'absolute',
                                left: '54px',
                                top: '189px',
                                width: '35px',
                                height: '35px',
                                // zIndex: 0,
                            }}
                            className='lottie-global'
                        />
                        <Lottie
                            options={pinOptions}
                            style={{
                                position: 'absolute',
                                left: '129px',
                                top: '359px',
                                width: '35px',
                                height: '35px',
                                // zIndex: 0,
                            }}
                            className='lottie-global'
                        />
                        <Lottie
                            options={pinOptions}
                            style={{
                                position: 'absolute',
                                left: '118px',
                                top: '368px',
                                width: '35px',
                                height: '35px',
                                // zIndex: 0,
                            }}
                            className='lottie-global'
                        />
                    </div>
                    <div className='legend'>
                        <ul className='legend-info'>
                            <li><div className='pin active-pin-legend'></div></li>
                            <li className='title'>Osada w sprzedaży</li>
                            <li><div className='pin inactive-pin-legend'></div></li>
                            <li className='title'>Osada sprzedana </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HeroSection;