import React from 'react';
import '../styles/dictionary-styles.scss';

// ----------------------------------------------------------------------

function Dictionary() {
    return (
        <div className='dc-container'>
            <div className='dc-content-container'>
                Dictionary
            </div>
        </div>
    )
}

export default Dictionary;