import React, { useEffect, useRef } from 'react';
import { Formik, Field, Form } from 'formik';
import '../styles/sell-ground-styles.scss';
import TitleDir from '../components/TitleDir';
import sellgroundGrid from '../assets/images/sellground-grid.png';
import iconArrow from '../assets/images/iconArrow.png';
import icCompleted from '../assets/icons/sell-ground/ic-completed.png';

// ----------------------------------------------------------------------

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

function SellGround() {

    const contactFormRef = useRef(null);

    const bgcFunc = () => {
        const footerBg = document.getElementsByClassName('footer-container')[0];
        footerBg.style.background = 'var(--c-custom-10)';
        // console.info(`kolor zmieniony. element: ${footerBg}}`);
    }

    useEffect(() => {
        bgcFunc();
    });

    return (
        <>
            <div className='sellground-container bootstrap-cont'>
                <div className='career-section-container'>
                    <div className="container">
                        <div className="row no-underline">
                            <div className="col-md-6">
                                <TitleDir>Sprzedaj grunt _</TitleDir>
                                <p className="sellground-title">Sprzedaj grunt bezpiecznie i szybko!</p>

                                <p className='main-description'>Nasz prosty, przejrzysty i przede wszystkim sprawdzony przez setki klientów proces sprzedaży działek sprawi, że Twoje rozterki i wahania znikną.</p>

                                <div className="sell-ground" onClick={() => contactFormRef.current.scrollIntoView()}>
                                    <div>Wypełnij formularz</div>
                                    <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                                </div>

                                <p className="sellground-title-third">Szukamy gruntów budowlanych, które:</p>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">posiadają Miejscowy Plan Zagospodarowania Przestrzennego,</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">są położone w atrakcyjnych regionach Polski,</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">mają uregulowaną sytuację prawną,</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">posiadają możliwość zabudowy,</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">mają powierzchnię powyżej 4 ha.</p>
                                    </div>
                                </div>

                                <p className="sellground-title-third">Potencjalne inwestycje sprawdzamy pod kątem:</p>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">prawnym,</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">możliwości zabudowy,</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">możliwości przyłączenia mediów,</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">atrakcyjności inwestycyjnej,</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={icCompleted} alt="Punkt" />
                                    </div>
                                    <div className="col-8">
                                        <p className="sell-ground-point">urbanizacji okolicy</p>
                                    </div>
                                </div>


                            </div>

                            <div className="col-md-6">
                                <img src={sellgroundGrid} alt='Sprzedaj grunt' className='sellground-img-grid' />
                                <p className="sellground-title-second">Sprzedaj nieruchomość w 4 prostych krokach:</p>

                                <div className='sellground-list'>
                                    <div className='lineY' />

                                    <div className='dot dot1'>
                                        <div className="dot-numbers">1</div>
                                    </div>
                                    <p className='purchase-name pn1'>Wybór działki i jej wstępna rezerwacja</p>

                                    <div className='dot dot2'>
                                        <div className="dot-numbers">2</div>
                                    </div>

                                    <p className='purchase-name pn2'>Decyzja o dokonaniu zakupu</p>
                                    <div className='dot dot3'>
                                        <div className="dot-numbers">3</div>
                                    </div>

                                    <p className='purchase-name pn3'>Podpisanie umowy</p>
                                    <div className='dot dot4'>
                                        <div className="dot-numbers">4</div>
                                    </div>

                                    <p className='purchase-name pn4'>Wpłata środków na poczet rezerwacji</p>
                                </div>



                                {/* end */}
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-12 departments-cont">
                                <p className="departments-title">Zobacz, w jakim dziale mozesz pracować</p>
                                <p className="departments-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat, ipsum ac auctor ornare, nunc ligula scelerisque eros.
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* wydziel ponizsze do osobnego komponentu */}
            <div className="sellground-container" ref={contactFormRef}>
                <div className="sellground-cont container bootstrap-cont">
                    <div className="col-12">
                        <p className="sellground-title">Wypełnij formularz kontaktowy, a nasz zespół przygotuje indywidualną wycenę.</p>

                        {/* start */}

                        <div className="col-md-5 sellground-form" style={{ float: 'none', margin: 'auto' }}>
                            <Formik
                                initialValues={{
                                    toggle: false,
                                    checked: [],
                                }}
                                onSubmit={async (values) => {
                                    await sleep(500);
                                    alert(JSON.stringify(values, null, 2));
                                }}
                            >
                                {({ values }) => (
                                    <Form>

                                        <label htmlFor="tel">Numer telefonu:*</label><br />
                                        <input type="text" name="tel" placeholder="Numer telefonu" className="inputStyles" /><br /><br />

                                        <label htmlFor="email">Adres e-mail:</label><br />
                                        <input type="text" name="email" placeholder="twoj@email.com" className="inputStyles" /><br /><br />

                                        <div className="row">
                                            <div className="col-6">
                                                <label htmlFor="tel">Metraz działki:*</label><br />
                                                <input type="text" name="tel" placeholder="minimum 4 h" className="inputStyles" /><br /><br />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="tel">Cena dzialki:*</label><br />
                                                <input type="text" name="tel" placeholder="np. 30 000 zł" className="inputStyles" /><br /><br />
                                            </div>
                                        </div>

                                        <label htmlFor="name">Miejscowość:*</label><br />
                                        <input type="text" name="name" placeholder="Miejscowość" className="inputStyles" /><br /><br />

                                        <div className="row">
                                            <div className="col-6">
                                                <label htmlFor="tel">Kod pocztowy:*</label><br />
                                                <input type="text" name="tel" placeholder="00-000" className="inputStyles" /><br /><br />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="tel">Województwo:*</label><br />
                                                <input type="text" name="tel" placeholder="Województwo" className="inputStyles" /><br /><br />
                                            </div>
                                        </div>

                                        <label htmlFor="msg">Dodatkowe informacje:</label><br />
                                        <textarea name="msg" placeholder="Wpisz dodatkowe informacje..." className="inputStyles inputAreaStyles" /><br /><br />

                                        <label className="inputDescSmall">
                                            <Field type="checkbox" name="agreement" />
                                            Wyrażam zgodę na przetwarzanie danych osobowych. Czytaj więcej. *
                                        </label>

                                        <label className="inputDescSmall">
                                            <Field type="checkbox" name="newsletter" />
                                            Chcę dostawać newsletter na podany adres e-mail.
                                        </label>

                                        <br />
                                        <button type="submit" className="btnContact">Wyślij wiadomość</button>
                                    </Form>
                                )}
                            </Formik>
                            <p className="contact-inf">Zastrzegamy sobie prawo do kontaktu z wybranymi osobami.</p>

                        </div>

                        {/* end */}


                    </div>
                </div>
            </div>


        </>
    )
}

export default SellGround;

// najpewniej to do accordion https://github.com/obrm/Accordion
// https://github.com/devzonetech/react-collapsible-component