import React from 'react';
import '../styles/hero-blank-styles.scss';

// ----------------------------------------------------------------------

function HeroBlank({ title, description }) {
    return (
        <div className='hb-container'>
            <div className='hero-blank-container'>
                <p className='hero-title'>{title}</p>
                <div className='hero-description'>{description}</div>
            </div>
        </div>
    )
}

export default HeroBlank