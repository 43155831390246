import React from 'react';

function TitleDir(props) {
    return (
        <p style={{
            fontSize: '13px',
            letterSpacing: '0.01em',
            color: 'var(--c-primary-500)',
            textTransform: 'uppercase',
            marginBlockEnd: '2px'
        }}>{props.children}</p>
    )
}

export default TitleDir;