import React, { useEffect, useState } from "react";
import '../styles/backtotop-styles.scss';
// [assets]
import iconArrowDark from '../assets/images/iconArrowDark.png';

// ----------------------------------------------------------------------

const ScrollToTop = (props) => {
    const [stick, setStick] = useState(false);
    // const onClickHandler = () => {
    //     window.scrollTo({ top: 0, behavior: "smooth" });
    // };

    useEffect(() => {
        var position = window.pageYOffset;

        const scrollHandler = () => {
            let scrollPos = window.pageYOffset;
            if (scrollPos < 200) {
                setStick(false);
            } else if (scrollPos < position) {
                setStick(true);
            } else {
                setStick(false);
            }
            position = scrollPos;
        };

        window.addEventListener("scroll", function () {
            scrollHandler();
        });
        return () => {
            window.removeEventListener("scroll", function () {
                scrollHandler();
            });
        };
    }, [stick]);

    return (
        <div className={` ${stick ? 'btn-btt' : 'hidden'}`} onClick={() => { window.scrollTo({ top: 0, behavior: "smooth" }) }}>
            <span>Wróć do góry</span>
            <img src={iconArrowDark} width="12" height="12" alt="strzałka"></img>
        </div>
    );
};

export default ScrollToTop;

// +hope-health-medical-react-js-template-2022-02-21-23-52-56-utc :: header NOWY pojawiajacy sie po scrollu, rozwijajacy sie div do 'FAQ', SCROLL TO TOP po przejechaniu w gore