import React from 'react';
import '../styles/header-styles.scss';
// [assets]
import logo from '../assets/logo-site.png';
import iconArrow from '../assets/images/iconArrow.png';

// ----------------------------------------------------------------------

// const menuList = [{
//     url: ''
// }]

function Header() {
    return (
        <header className="hd-container">
            <div className='header-container'>
                <div className='logo-nav-container'>
                    <a href='/'>
                        <img id='logo' src={logo} alt='Ziemia Inwestycje' width='142' height='41' />
                    </a>
                    <ul className='main-menu'>
                        <li className='links'><a href="nasze-osady" className='underLink'>NASZE OSADY</a></li>
                        <li className='links'><a href="o-nas" className='underLink'>O NAS</a></li>
                        <li className='links'><a href="proces-zakupu" className='underLink'>PROCES ZAKUPU</a></li>
                        <li className='links'><a href="czytelnia" className='underLink'>CZYTELNIA</a></li>
                        <li className='links'><a href="kariera" className='underLink'>KARIERA</a></li>
                        <li className='links'><a href="kontakt" className='underLink'>KONTAKT</a></li>
                    </ul>
                </div>

                <ul className='client-menu'>
                    <li className="sell-ground">
                        <a href="sprzedaj-grunt" className='underLink'>SPRZEDAJ GRUNT</a>
                    </li>
                    <li className="buy-ground">
                        <a href="{NULL}"><span>ZNAJDŹ DZIAŁKĘ</span></a>
                        <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                    </li>
                </ul>
            </div>
        </header >
    )
}

export default Header;