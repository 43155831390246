import React from 'react';
import SettlementRouter from '../components/SettlementRouter';
import { ReactComponent as IcAlert } from '../assets/images/symbol/ic-alert.svg';
import '../styles/error-404-styles.scss';

// ----------------------------------------------------------------------

function Error404() {
    return (
        <>
            <div className='err-container'>
                <div className='err-section-container'>
                    <IcAlert />
                    <p className='error-title'>Błąd 404</p>
                    <h1>Ups! Coś poszło nie tak...</h1>
                    <p>Strona nie została znaleziona.</p>
                </div>
            </div>
            <SettlementRouter />
        </>
    )
}

export default Error404;