import React, { useRef } from 'react';
import SettlementRouter from '../components/SettlementRouter';
import '../styles/about-us-styles.scss';
import '../styles/libs/bootstrap.min.scss';
// import companyHistory from '../data/companyHistory';
// [assets] images
import aboutusGrid from '../assets/images/aboutus-grid.png';
import aboutusPointer from '../assets/images/aboutusPointer.png';
import aboutusOwk from '../assets/images/aboutus-owk.png';
import aboutusOlp from '../assets/images/aboutus-olp.png';
import aboutusOkz from '../assets/images/aboutus-okz.png';
import aboutusOdw from '../assets/images/aboutus-odw.png';
import aboutusOgb from '../assets/images/aboutus-ogb.png';
import aboutusOjd from '../assets/images/aboutus-ojd.png';
import aboutusOsg from '../assets/images/aboutus-osg.png';
import aboutusOsd from '../assets/images/aboutus-osd.png';

// ----------------------------------------------------------------------

function AboutUs() {

    const tl2022 = useRef(null);
    const tl2021 = useRef(null);
    const tl2020 = useRef(null);
    const tl2019 = useRef(null);
    const tl2018 = useRef(null);
    const tl2017 = useRef(null);
    const tl2016 = useRef(null);
    const tl2015 = useRef(null);

    return (
        <>
            <div className='about-container bootstrap-cont'>
                <div className='about-section-container'>
                    <div className='about-left'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 description-about">
                                    <div className="tf-title mb-30" data-aos="fade-up" data-aos-duration="800">
                                        <p className="title-hero">O nas _</p>
                                        <h4 className="main-title">Inwestuj z zaufanym partnerem</h4>
                                        <img src={aboutusGrid} alt='O nas' className='about-img-grid' />

                                        <p className='title-hero'>7 lat na rynku _</p>
                                        <h4 className="second-title">Niespełna dekadę temu stworzyliśmy projekt Ziemia Inwestycje</h4>
                                        <p className='title-hero-secondary'>Lata naszej działalności, utwierdziły nas w przekonaniu, że inwestowanie w ziemię niesie ze sobą ogromne korzyści nie imają się go kryzysy. A nasze nieszablonowe podejście do inwestycji opartej na gruncie okazało się strzałem w dziesiątkę.</p>
                                        <ul className='year-list'>
                                            <li onClick={() => tl2022.current.scrollIntoView()}>2022</li>
                                            <li>|</li>
                                            <li onClick={() => tl2021.current.scrollIntoView()}>2021</li>
                                            <li>|</li>
                                            <li onClick={() => tl2020.current.scrollIntoView()}>2020</li>
                                            <li>|</li>
                                            <li onClick={() => tl2019.current.scrollIntoView()}>2019</li>
                                            <li>|</li>
                                            <li onClick={() => tl2018.current.scrollIntoView()}>2018</li>
                                            <li>|</li>
                                            <li onClick={() => tl2017.current.scrollIntoView()}>2017</li>
                                            <li>|</li>
                                            <li onClick={() => tl2016.current.scrollIntoView()}>2016</li>
                                            <li>|</li>
                                            <li onClick={() => tl2015.current.scrollIntoView()}>2015</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="roadmap scrol">

                                        {
                                            companyHistory.map(item => (
                                                <div key={item.id} item={item} />
                                            ))
                                        }
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='about-right'>

                        <p className='main-description'>Niespełna dekadę temu stworzyliśmy projekt Ziemia Inwestycje, którego celem było jak najlepsze połączenie inwestycji i gruntów. Kierował nami optymizm w kreowaniu nieszablonowego biznesu i przekonanie, że możemy oferować naszym klientom znacznie więcej niż zysk z inwestycji.<br /><br /> Poza lokatą finansową i możliwością wygenerowania biernego przychodu chcieliśmy dać naszym klientom coś, co wykracza daleko poza sferę materialną - radość, szczęście i możliwość złapania oddechu od zbyt intensywnej codzienności na własnym kawałku ziemi.<br /><br />Chcieliśmy stworzyć niezwykłe miejsca w najpiękniejszych zakątkach Polski, w których nasi klienci mogliby czerpać z piękna natury pełnymi garściami w poczuciu dobrze zainwestowanych pieniędzy.<br /><br /> Tak zrodziła się idea osad - urokliwych miejsc złożonych z działek przygotowanych do zabudowy w otoczeniu czystej, nieskalanej współczesnym pędem natury, z bogactwem turystycznych atrakcji wokół.</p>

                        <div className='timeline-events'>
                            <div className='event now' ref={tl2022}>
                                <p>Historia toczy się dalej! Mozesz stać się jej częścią!</p>
                                <a href='kariera' alt='Oferty pracy' className='link'>Sprawdź oferty pracy</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>dziś</div>
                            </div>
                            <div className='event past'>
                                <div>Sprzedaliśmy już ponad 630000 m2 działek, a to więcej niż wynosi powierzchnia Warszawy.</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>maj <br /> 2022</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOwk} alt="Osada Wzgórza Kaszubskie" className='photosettlement' />
                                <p>Finalizacja projektu budowy pięciu domów całorocznych w Osadzie Wzgórza Kaszubskie</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>maj <br /> 2022</div>
                            </div>
                            <div className='event past'>
                                <div>Już 30 wykwalifikowanych specjalistów tworzy zespół Ziemia Inwestycje</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>kwiecień <br /> 2022</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOlp} alt="Osada Lubuska Przystań" className='photosettlement' />
                                <p>Wcielamy w zycie projekt Osada Lubuska Przystań</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>marzec <br /> 2022</div>
                            </div>
                            <div className='event past' ref={tl2021} >
                                <img src={aboutusOkz} alt="Osada Kujawska Zatoka" className='photosettlement' />
                                <p>Rozpoczynamy inwestycję Osada Kujawska Zatoka</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date' >grudzień <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOdw} alt="Osada Dolina Warty" className='photosettlement' />
                                <p>Rozpoczynamy inwestycję Osada Dolina Warty</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>grudzień <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <div>Rok zakończyliśmy sprzedażą 500 działek. <br /> Pół tysiąca - to robi wrażenie!</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>grudzień <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <div>Nasze inwestycje świetnie się sprzedają - mamy już 400 sfinalizowanych transakcji</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>listopad <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOgb} alt="Osada Góry Bardzkie" className='photosettlement' />
                                <p>Ruszamy z realizacją projektu Osada Góry Bardzkie - to już trzecia nasza osada w Dolinie Wilczy</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>wrzesień <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <div>Kolejna przeprowadzka - Nowa siedziba Ziemia Inwestycje Prosta 32, Marvipol</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>wrzesień <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOjd} alt="Osada Jezioro Dołgie" className='photosettlement' />
                                <p>Rozpoczynamy sprzedaż działek w Osadzie Jezioro Dołgie</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>lipiec <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <div>Nasz zespół wciąż się rozrasta, mamy już 20 specjalistów</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>czerwiec <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOsg} alt="Osada Srebrna Góra" className='photosettlement' />
                                <p>Wdrażamy w życie realizację inwestycji Osada Srebrna Góra</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>luty <br /> 2021</div>
                            </div>
                            <div className='event past'>
                                <div>Sprzedaż działek przez Ziemia Inwestycje osiągnęła liczbę 300</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>luty <br /> 2021</div>
                            </div>
                            <div className='event past' ref={tl2020}>
                                <img src={aboutusOsg} alt="Osada Góry Sowie" className='photosettlement' />
                                <p>Zaczynamy realizację projektu Osada Góry Sowie</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>wrzesień <br /> 2020</div>
                            </div>
                            <div className='event past'>
                                <div>200 działek znalazło już nowych właścicieli</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>lipiec <br /> 2020</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOwk} alt="Osada Wzgórza Kaszubskie" className='photosettlement' />
                                <p>Rozpoczęcie realizacji projektu Osada Wzgórza Kaszubskie</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>marzec <br /> 2020</div>
                            </div>
                            <div className='event past' ref={tl2019}>
                                <div>Sprzedaliśmy już 100 działek w naszych osadach</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>lipiec <br /> 2019</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOsd} alt="Osada Potok" className='photosettlement' />
                                <p>Wszystkie działki w Osadzie Potok mają nowych właścicieli</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>maj <br /> 2019</div>
                            </div>
                            <div className='event past'>
                                <div>Nowy Dział Marketingu rusza pełną parą</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>kwiecień <br /> 2019</div>
                            </div>
                            <div className='event past' ref={tl2018}>
                                <div>Rok kończymy przeprowadzką do przestronnych biur Atlas Tower</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>grudzień <br /> 2018</div>
                            </div>
                            <div className='event past'>
                                <div>Ziemia Inwestycje się rozrasta. Powiększamy nasz zespół o Dział Produktu, który od A do Z realizuje wszelkie działania związane z przygotowaniem działek w osadzie</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>wrzesień <br /> 2018</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOsd} alt="Osada Potok" className='photosettlement' />
                                <p>Pełni zachwytu nad Kotliną Kłodzką postanowiliśmy stworzyć w niej Osadę Zdrojowa</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>sierpień <br /> 2018</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOsd} alt="Osada Potok" className='photosettlement' />
                                <p>Finalizujemy zakup nowego gruntu i tworzymy Osadę Zacisze Narwi</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>sierpień <br /> 2018</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOsd} alt="Osada Potok" className='photosettlement' />
                                <p>Pierwsza działka z osady Świerkowy Bór ma nowego właściciela</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>czerwiec <br /> 2018</div>
                            </div>
                            <div className='event past' ref={tl2017}>
                                <img src={aboutusOsd} alt="Osada Potok" className='photosettlement' />
                                <p>Zakup kolejnego gruntu pod inwestycję - powstaje nowa Osada Świerkowy Bór w zaciszu Mazowieckiego Parku Krajobrazowego</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>maj <br /> 2018</div>
                            </div>
                            <div className='event past' ref={tl2016}>
                                <img src={aboutusOsd} alt="Osada Potok" className='photosettlement' />
                                <p>Sprzedaż pierwszej działki w Osadzie Potok - nasz projekt nabiera wiatru w żagle!</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>wrzesień <br /> 2016</div>
                            </div>
                            <div className='event past'>
                                <img src={aboutusOsd} alt="Osada Potok" className='photosettlement' />
                                <p>Sfinalizowanie zakupu gruntu w malowniczym Beskidzie Niskim - pierwszy projekt Osada Potok zaczyna nabierać realnych kształtów</p>
                                <a href='nasze-osady' alt='Poznaj osadę' className='link'>Poznaj osadę</a>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>marzec <br /> 2016</div>
                            </div>
                            <div className='event past' ref={tl2015}>
                                <div>Ziemia Inwestycje się rozrasta. Powiększamy nasz zespół o Dział Produktu, który od A do Z realizuje wszelkie działania związane z przygotowaniem działek w osadzie</div>
                                <img src={aboutusPointer} alt="Wydarzenie" className='point' />
                                <div className='point-date'>listopad <br /> 2015</div>
                            </div>
                        </div>
                    </div>






                </div>
            </div>


            {/*  */}
            <SettlementRouter />
        </>
    )
}

export default AboutUs;