import React, { useRef, useEffect, useState } from 'react';
import '../styles/career-styles.scss';
import TitleDir from '../components/TitleDir';
import careerGrid from '../assets/images/career-grid.png';
import careerNumbers from '../assets/images/career-numbers.png';
import iconArrow from '../assets/images/iconArrow.png';
import icArrowRight from '../assets/images/shapes/ic-arrow-right.png';
// [assets] boxes
import boxHr from '../assets/images/career/boxHr.png';
import boxTechnical from '../assets/images/career/boxTechnical.png';
import boxFinancial from '../assets/images/career/boxFinancial.png';
import boxTerenSelling from '../assets/images/career/boxTerenSelling.png';
import boxAdministration from '../assets/images/career/boxAdministration.png';
import boxSellingSupport from '../assets/images/career/boxSellingSupport.png';
import boxMarketingSelling from '../assets/images/career/boxMarketingSelling.png';
import boxAgreement from '../assets/images/career/boxAgreement.png';
// [assets] icons , shapes
import arrowRight from '../assets/images/shapes/arrowRightBlack.png';

// ----------------------------------------------------------------------

function Career() {

    const careerFormRef = useRef(null);

    const [isJobs, setIsJobs] = useState(true);

    const bgcFunc = () => {
        const footerBg = document.getElementsByClassName('footer-container')[0];
        footerBg.style.background = 'var(--c-custom-10)';
        // console.info(`kolor footera zmieniony. element: ${footerBg}}`);

        // 
        const acc = document.getElementsByClassName("accordion");
        let i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                const panel = this.nextElementSibling;
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                } else {
                    panel.style.display = "block";
                    panel.style.color = "#006633";
                }
            });
        }
    }

    useEffect(() => {
        bgcFunc();
    });

    return (
        <>
            <div className='career-container bootstrap-cont'>
                <div className='career-section-container'>
                    <div className="container">
                        <div className="row underline">
                            <div className="col-md-6">
                                <TitleDir>Kariera _</TitleDir>
                                <p className="contact-title" onClick={() => setIsJobs(!isJobs)}>Jesteśmy liderem na rynku w Polsce</p>

                                <p className='main-description'>Ziemia Inwestycje to bardzo dynamicznie rosnąca organizacja działająca na rynku od ponad 7 lat, posiadająca ponad 500 zadowolonych klientów. Przekazaliśmy w ręce naszych klientów ponad 600.000 m2 działek budowlanych w 9 osadach na terenie Polski. <br /><br />Nasz 30-osobowy zespół cechuje wysoki poziom energiii skupienie na wyniku. Cenimy otwartą komunikację, samodzielność w kreowaniu rozwiązań i podejmowaniu decyzji.</p>

                                <div className="buy-ground" onClick={() => careerFormRef.current.scrollIntoView()}>
                                    <div>Sprawdź oferty pracy</div>
                                    <img src={iconArrow} width="12" height="12" alt="strzałka"></img>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <img src={careerGrid} alt='O nas' className='career-img-grid' />
                                <img src={careerNumbers} alt="7 lat na rynku" className='career-img-numbers' />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 departments-cont">
                                <p className="departments-title">Zobacz, w jakim dziale mozesz pracować</p>
                                <p className="departments-desc">
                                    Poznaj charakterystykę naszych działów i dowiedz się w kilku słowach czym się zajmują.
                                </p>

                                {/* accordion start */}
                                <div className="row">
                                    <div className="col-6">
                                        <div className="accordion">
                                            <img src={boxHr} className="boxOffice" alt="Dział HR" />
                                            <div className="title">Dział HR</div>
                                            <img src={arrowRight} className="arrowCareer" alt="Strzałka" />
                                        </div>
                                        <div className="panel">
                                            <p>Dział HR jest częścią Departamentu Operacyjnego. Zajmuje się wszelkimi działaniami hr-owymi, mi.in. rekrutacją, planowaniem szkoleń, rozliczaniem wynagrodzeń. Dołączając do tego działu będziesz miał/a istotny wpływ na kształtowanie miękkiego HR-u, rekrutacje pożądanych talentów oraz zostaniesz zaangażowany we wszelkie projekty employer brandingowe.</p>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="accordion">
                                            <img src={boxTechnical} className="boxOffice" alt="Dział Techniczny" />
                                            <div className="title">Dział Techniczny</div>
                                            <img src={arrowRight} className="arrowCareer" alt="Strzałka" />
                                        </div>
                                        <div className="panel">
                                            <p>Dział techniczny jest częścią Departamentu Produktu. Zajmuje się przygotowaniem produktu do sprzedaży. Dział jest odpowiedzialny za podział gruntu, zagospodarowanie przestrzeni wokół inwestycji m.in. przygotowanie drogi dojazdowej, podłącza mediów, stworzenie części wspólnej na terenie inwestycji. Praca w dziale wiąże się z pracą wyjazdową. Nasz produkt jest wyjątkowy – będziesz miał/a okazje pracować w urokliwych miejscach blisko natury.</p>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="accordion">
                                            <img src={boxFinancial} className="boxOffice" alt="Dział Finansów" />
                                            <div className="title">Dział Finansów</div>
                                            <img src={arrowRight} className="arrowCareer" alt="Strzałka" />
                                        </div>
                                        <div className="panel">
                                            <p>Dział finansów jest częścią Departamentu Operacyjnego. Zajmuje się zarządzaniem budżetem, planowaniem kosztów, analizą liczb i ścisłą współpracą z Zarządem spółki. Dołączając do tego działu będziesz zaangażowany/a w tworzenie i planowanie budżetu, controlling finansowy i raportowanie.</p>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="accordion">
                                            <img src={boxTerenSelling} className="boxOffice" alt="Dział Sprzedazy Terenowej" />
                                            <div className="title">Dział Sprzedazy Terenowej</div>
                                            <img src={arrowRight} className="arrowCareer" alt="Strzałka" />
                                        </div>
                                        <div className="panel">
                                            <p>Dział sprzedaży terenowej jest częścią Departamentu Marketingu i Sprzedaży. Zajmuje się bezpośrednią obsługą Klientów na terenie inwestycji.
                                                Dział jest odpowiedzialny za sprzedaż działki, podpisanie umowy przedwstępnej/rezerwacyjnej oraz budowanie pozytywnych relacji z Klientem. Szukamy osób dyspozycyjnych, które posiadają obycie w terenie, są zorientowane na Klienta i nie straszna jest im praca w różnych warunkach pogodowych.</p>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="accordion">
                                            <img src={boxAdministration} className="boxOffice" alt="Dział Administracji" />
                                            <div className="title">Dział Administracji</div>
                                            <img src={arrowRight} className="arrowCareer" alt="Strzałka" />
                                        </div>
                                        <div className="panel">
                                            <p>Dział administracji jest częścią Departamentu Operacyjnego. Składa się z administracji i recepcji. Odpowiada za cały support organizacji w kwestiach administracyjnych, zarządzania flotą, korespondencją przychodzącą i wychodzącą oraz opieką nad biurem. Dołączając do tego zespołu możesz liczyć na dużą samodzielność, możliwość kreowania nowych działań, usprawnień. W tym dziale ważne jest dla nas zatrudnienie osób, które biuro potraktują jak drugi dom – czyli się nim zaopiekują 😊</p>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="accordion">
                                            <img src={boxSellingSupport} className="boxOffice" alt="Dział Wsparcia Sprzedazy" />
                                            <div className="title">Dział Wsparcia Sprzedazy</div>
                                            <img src={arrowRight} className="arrowCareer" alt="Strzałka" />
                                        </div>
                                        <div className="panel">
                                            <p>Dział wsparcia sprzedaży jest częścią Departamentu Marketingu i Sprzedaży. Zajmuje się posprzedażową obsługą Klientów, pozyskiwaniem i budowaniem relacji z pośrednikami. W tym dziale szukamy osób dla których budowanie relacji z Klientem będzie przychodzić naturalnie i sprawiać satysfakcje.</p>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="accordion">
                                            <img src={boxMarketingSelling} className="boxOffice" alt="Dział Marketingu i Sprzedazy" />
                                            <div className="title">Dział Marketingu i Sprzedazy</div>
                                            <img src={arrowRight} className="arrowCareer" alt="Strzałka" />
                                        </div>
                                        <div className="panel">
                                            <p>Zajmuje się przygotowaniem kampanii marketingowych, zarządzaniem kanałami social media oraz współpracą z zewnętrznymi grafikami oraz agencjami. W tym dziale nie będziesz się nudzić. Stawiamy na kreatywność i samodzielność w działaniu. Liczymy również na otwartą komunikacje, ponieważ dział marketingu ściśle współpracuje z działem sprzedaży oraz produktu.</p>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="accordion">
                                            <img src={boxAgreement} className="boxOffice" alt="Dział Umów" />
                                            <div className="title">Dział Umów</div>
                                            <img src={arrowRight} className="arrowCareer" alt="Strzałka" />
                                        </div>
                                        <div className="panel">
                                            <p>Dział umów jest częścią Departamentu Marketingu i Sprzedaży. Zajmuje się reprezentowaniem spółki przy podpisywaniu aktów notarialnych. To właśnie w tym dziale będziesz odpowiedzialny/a za finalizacje procesu sprzedaży. Praca wymaga dyspozycyjności, ponieważ akty notarialne są podpisywane w różnych miejscach w Polsce. Szukamy osób, które mają łatwość w nawiązywaniu kontaktu, są dyspozycyjne i chcą zdobyć pierwsze doświadczenie w pracy w sprzedaży i obsłudze Klienta.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* accordion end */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* wydziel ponizsze do osobnego komponentu */}
            <div className="career-container" ref={careerFormRef}>
                <div className="offers-cont container bootstrap-cont">
                    <div className="col-12">
                        <p className="offers-title">Aktualne oferty pracy</p>

                        {isJobs ?
                            <>
                                {/* MAP START */}
                                <div className="offers-map">
                                    <p className="departments-name">Dział techniczny</p>
                                    <div className="single-offer-cont">
                                        <div>
                                            <p className="offer-title">Manager działu technicznego w branzy nieruchomosci</p>
                                            <ul className="parametr-offer">
                                                <li>Warszawa</li>
                                                <li>|</li>
                                                <li>B2B/UoP</li>
                                                <li>|</li>
                                                <li>Manager</li>
                                                <li>|</li>
                                                <li>Pełny etat</li>
                                            </ul>
                                        </div>
                                        <div className="offer-details">
                                            <a href='#' alt="sprawdź szczegóły" className="href-details">Sprawdź szczegóły</a>
                                            <img src={icArrowRight} alt="strzałka" />
                                        </div>
                                    </div>
                                </div>
                                {/* MAP END */}

                                <div className="offers-map">
                                    <p className="departments-name">Dział Sprzedazy Terenowej</p>
                                    {/*  */}

                                    <div className="single-offer-cont">
                                        <div>
                                            <p className="offer-title">Specjalista ds/ sprzedazy terenowej</p>
                                            <ul className="parametr-offer">
                                                <li>Gorzów Wielkopolski</li>
                                                <li>|</li>
                                                <li>UoP</li>
                                                <li>|</li>
                                                <li>Specjalista (mid/regular)</li>
                                                <li>|</li>
                                                <li>Pełny etat</li>
                                            </ul>
                                        </div>
                                        <div className="offer-details">
                                            <a href='#' alt="sprawdź szczegóły" className="href-details">Sprawdź szczegóły</a>
                                            <img src={icArrowRight} alt="strzałka" />
                                        </div>
                                    </div>

                                    <div className="single-offer-cont">
                                        <div>
                                            <p className="offer-title">Specjalista ds/ sprzedazy terenowej</p>
                                            <ul className="parametr-offer">
                                                <li>Srebrna Góra</li>
                                                <li>|</li>
                                                <li>UoP</li>
                                                <li>|</li>
                                                <li>Specjalista (mid/regular)</li>
                                                <li>|</li>
                                                <li>Pełny etat</li>
                                            </ul>
                                        </div>
                                        <div className="offer-details">
                                            <a href='#' alt="sprawdź szczegóły" className="href-details">Sprawdź szczegóły</a>
                                            <img src={icArrowRight} alt="strzałka" />
                                        </div>
                                    </div>

                                </div>
                            </>

                            :
                            <div className='no-jobs'>Brak ofert pracy. W tym momencie nie mamy otwartych żadnych rekrutacji.</div>
                        }

                    </div>
                </div>
            </div>


        </>
    )
}

export default Career;

// najpewniej to do accordion https://github.com/obrm/Accordion
// https://github.com/devzonetech/react-collapsible-component