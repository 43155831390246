import React, { useRef } from 'react'
import HeroSection from '../components/HeroSection';
import SettlementMap from '../components/SettlementMap';
import SettlementRouter from '../components/SettlementRouter';

// ----------------------------------------------------------------------

function OurSettlements() {

    // https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks
    const osdRef = useRef(null);
    const owkRef = useRef(null);
    const ojdRef = useRef(null);
    const okzRef = useRef(null);
    const olpRef = useRef(null);
    const odwRef = useRef(null);
    const osgRef = useRef(null);
    const ogbRef = useRef(null);
    const ojmRef = useRef(null);
    const oleRef = useRef(null);
    const oznRef = useRef(null);
    const osbRef = useRef(null);
    const ogsRef = useRef(null);
    const ogs2Ref = useRef(null);
    const ozdRef = useRef(null);
    const opoRef = useRef(null);

    return (
        <>
            <HeroSection osdRef={osdRef} owkRef={owkRef} ojdRef={ojdRef} okzRef={okzRef} olpRef={olpRef} odwRef={odwRef} osgRef={osgRef} ogbRef={ogbRef} ojmRef={ojmRef} oleRef={oleRef} oznRef={oznRef} osbRef={osbRef} ogsRef={ogsRef} ogs2Ref={ogs2Ref} ozdRef={ozdRef} opoRef={opoRef} />
            <SettlementMap osdRef={osdRef} owkRef={owkRef} ojdRef={ojdRef} okzRef={okzRef} olpRef={olpRef} odwRef={odwRef} osgRef={osgRef} ogbRef={ogbRef} ojmRef={ojmRef} oleRef={oleRef} oznRef={oznRef} osbRef={osbRef} ogsRef={ogsRef} ogs2Ref={ogs2Ref} ozdRef={ozdRef} opoRef={opoRef} />
            <SettlementRouter />
        </>
    )
}

export default OurSettlements;