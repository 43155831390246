import React from 'react';
import '../styles/article-styles.scss';

// ----------------------------------------------------------------------

function Article() {
    return (
        <div className='article-container'>
            <div className='article-section-container'>
                <h1>Czytelnia</h1>
                <p>Powrót na stronę główną</p>
            </div>
        </div>
    )
}

export default Article;